define("dispel-client/helpers/remaining-trial", ["exports", "ember"], function (exports, _ember) {
  var RemainingTrialHelper, remainingTrial;

  exports.remainingTrial = remainingTrial = function (params) {
    var created_at, currentDate, expire_days, return_text;
    created_at = params[0];
    currentDate = new Date();
    expire_days = 3 - Math.round((currentDate - created_at) / (1000 * 24 * 60 * 60));
    if (expire_days < 0) {
      expire_days = 0;
    }
    if (expire_days === 1) {
      return_text = "day";
    } else {
      return_text = "days";
    }
    return "(" + expire_days + ")" + " " + return_text;
  };

  RemainingTrialHelper = _ember["default"].HTMLBars.makeBoundHelper(remainingTrial);

  exports.remainingTrial = remainingTrial;
  exports["default"] = RemainingTrialHelper;
});