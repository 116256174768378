define('dispel-client/features/components/service-status/component', ['exports', 'ember'], function (exports, _ember) {
  var ServiceStatusComponent;

  ServiceStatusComponent = _ember['default'].Component.extend({
    message: 'Establishing service status...',
    color: 'cyan-text text-accent-3',
    additionalClasses: '',
    symbol: 'group_work',
    symbolClass: 'rotating',
    reconnectAttemptCounter: 0,
    reconnectAttemptLimit: 5,
    reconnectStart: void 0,
    waiting: false,
    name: (function () {
      var _friendlyName, _service;
      _service = this.get('service');
      _friendlyName = _service.get('friendly_name');
      return _friendlyName;
    }).property('service'),
    imageName: (function () {
      var _imageName, _service, _shortName;
      _service = this.get('service');
      _shortName = _service.get('short_name');
      _imageName = _shortName.toLowerCase().replace(' ', '-');
      return _imageName;
    }).property('service'),
    socketIOService: _ember['default'].inject.service('socket-io'),
    socketPath: '',
    setup: (function () {
      var useSockets;
      useSockets = this.config.Variables.USE_SOCKETS;
      if (useSockets) {
        return this.socketSetup();
      } else {
        return this.restSetup();
      }
    }).on('init'),
    socketSetup: function socketSetup() {
      var socket, socketPath;
      socket = this.get('socket');
      socketPath = this.config.Variables.API_URL + '/api/socket/check-pooled/' + this.get('service.id');
      socket = this.get('socketIOService').socketFor(socketPath);
      this.set('socketPath', socketPath);
      socket.on('connect', function (event) {
        socket.on('featureAvailable', this.featureAvailable, this);
        socket.on('featureUnavailable', this.featureUnavailable, this);
      }, this);
      socket.on('error', function () {
        return this.disruptedFeature;
      }, this);
      socket.on('disconnect', function (disconnectData) {
        return this.disruptedFeature();
      }, this);
      socket.on('reconnect', function (reconnect) {
        this.set('reconnectStart', new Date());
        this.set('waiting', true);
        return this.checkWaitingStatus();
      }, this);
      socket.on('reconnect_attempt', function (attempt) {
        this.set('waiting', false);
        this.incrementProperty('reconnectAttemptCounter');
        this.set('reconnectStart', void 0);
        if (this.get('reconnectAttemptCounter') < this.get('reconnectAttemptLimit')) {
          return this.disruptedFeature(attempt);
        }
      }, this);
      socket.on('reconnect_error', function (error) {
        if (this.get('reconnectAttemptCounter') > this.get('reconnectAttemptLimit')) {
          return this.fallbackCheckStatus();
        }
      }, this);
      return socket.on('reconnect_failed', this.featureBroken, this);
    },
    restSetup: function restSetup() {
      this.fallbackCheckStatus();
      return setInterval((function (_this) {
        return function () {
          return _this.fallbackCheckStatus();
        };
      })(this), 10000);
    },
    willDestroyElement: function willDestroyElement() {
      return this.get('socketIOService').closeSocketFor(this.get('socketPath'));
    },
    featureAvailable: function featureAvailable(activeData) {
      this.set('message', activeData.message);
      this.set('color', 'green-text text-accent-4');
      this.set('additionalClasses', 'pulse');
      this.set('symbol', 'check');
      this.set('symbolClass', 'pulse');
      return this.set('service.available', true);
    },
    featureUnavailable: function featureUnavailable(inactiveData) {
      this.set('message', inactiveData.message);
      this.set('color', 'cyan-text text-accent-3');
      this.set('additionalClasses', '');
      this.set('symbol', 'group_work');
      this.set('symbolClass', 'rotating');
      return this.set('service.available', false);
    },
    disruptedFeature: function disruptedFeature(disruptedData) {
      this.set('message', 'System disrupted, repairing...');
      this.set('color', 'amber-text text-accent-4');
      this.set('additionalClasses', 'pulse');
      this.set('symbol', 'autorenew');
      this.set('symbolClass', 'rotating');
      return this.set('service.available', false);
    },
    featureBroken: function featureBroken(unavailableData) {
      this.set('message', 'No network connection');
      this.set('color', 'red-text text-accent-3');
      this.set('additionalClasses', 'pulse');
      this.set('symbol', 'report');
      this.set('symbolClass', 'pulse');
      return this.set('service.available', false);
    },
    featureReconnect: function featureReconnect(reconnectData) {
      this.set('message', 'Restablishing service...');
      this.set('color', 'cyan-text text-accent-3');
      this.set('additionalClasses', '');
      this.set('symbol', 'group_work');
      this.set('symbolClass', 'rotating');
      return this.set('service.available', false);
    },
    fallbackCheckStatus: function fallbackCheckStatus() {
      var _retrieveServiceStatus, _service, self;
      _service = this.get('service');
      self = this;
      if (_service) {
        _retrieveServiceStatus = _ember['default'].$.ajax({
          url: this.config.Variables.API_URL + '/' + this.config.Variables.URL_NAMESPACE + '/check-pooled/' + _service.get('id'),
          method: 'get'
        });
        _retrieveServiceStatus.done(function (_response, textStatus, xhr) {
          var inactiveData;
          if (xhr.status === 204 || /Building/.test(_response.message)) {
            inactiveData = {
              message: "Building more..."
            };
            return self.featureUnavailable(inactiveData);
          }
          return self.featureAvailable(_response);
        });
        return _retrieveServiceStatus.error(function (_response, textStatus, xhr) {
          return self.featureBroken();
        });
      }
    },
    checkElapsedReconnectTime: function checkElapsedReconnectTime() {
      var elapsed, end, start;
      start = this.get('reconnectStart');
      end = new Date();
      if (start && end) {
        elapsed = end.getTime() - start.getTime();
        if (elapsed > 3000) {
          return true;
        } else {
          return false;
        }
      }
    },
    checkWaitingStatus: function checkWaitingStatus() {
      if (this.checkElapsedReconnectTime() && this.get('waiting')) {
        this.set('reconnectAttemptCounter', 0);
        this.set('reconnectStart', void 0);
        return this.featureReconnect(reconnect);
      } else {
        return setTimeout(this.checkWaitingStatus(), 3000);
      }
    }
  });

  exports['default'] = ServiceStatusComponent;
});