define('dispel-client/features/signup/route', ['exports', 'ember', 'simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _ember, _simpleAuthMixinsUnauthenticatedRouteMixin) {
  var SignupRoute;

  SignupRoute = _ember['default'].Route.extend(_simpleAuthMixinsUnauthenticatedRouteMixin['default'], {
    titleToken: 'Sign Up',
    subscriptionPlans: [],
    basePlan: void 0,
    redirect: function redirect(model, transition) {
      if (transition.queryParams.verification) {
        this.controllerFor('signup.verify').set('verification', transition.queryParams.verification);
        return this.transitionTo('signup.verify');
      } else if (transition.targetName === 'signup.index') {
        return this.transitionTo('signup.credentials');
      }
    },
    model: function model() {
      return _ember['default'].RSVP.hash({
        user: this.store.createRecord('user'),
        plans: this.store.query('subscription', {
          noAuth: true
        })
      });
    },
    actions: {
      editEmail: function editEmail() {
        this.controllerFor('signup').setProperties({
          planTabAvailable: 'disabled',
          paymentTabAvailable: 'disabled',
          doneTabAvailable: 'disabled',
          credentialsTabComplete: false,
          planTabComplete: false,
          paymentTabComplete: false,
          doneTabComplete: false
        });
        return _ember['default'].run.next(function () {
          _ember['default'].$('#credentials').click();
          return this.transitionToRoute('signup.credentials');
        });
      },
      editPlan: function editPlan() {
        this.controllerFor('signup').setProperties({
          paymentTabAvailable: 'disabled',
          doneTabAvailable: 'disabled',
          planTabComplete: false,
          paymentTabComplete: false,
          doneTabComplete: false
        });
        return _ember['default'].run.next(function () {
          _ember['default'].$('#plans').click();
          return this.transitionToRoute('signup.plans');
        });
      },
      editPayment: function editPayment() {
        this.controllerFor('signup').setProperties({
          doneTabAvailable: 'disabled',
          paymentTabComplete: false,
          doneTabComplete: false
        });
        return _ember['default'].run.next(function () {
          _ember['default'].$('#payment').click();
          return this.transitionToRoute('signup.payment');
        });
      }
    }
  });

  exports['default'] = SignupRoute;
});