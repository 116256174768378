define('dispel-client/features/billing/receipt/controller', ['exports', 'ember'], function (exports, _ember) {
  var BillingReceiptsController;

  BillingReceiptsController = _ember['default'].Controller.extend({
    pageName: 'Receipts',
    actions: {
      printDiv: function printDiv() {
        var originalContents, printContents;
        printContents = document.getElementById('printDiv').innerHTML;
        originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        return document.body.innerHTML = originalContents;
      }
    }
  });

  exports['default'] = BillingReceiptsController;
});