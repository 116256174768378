define('dispel-client/features/application/route', ['exports', 'ember', 'simple-auth/mixins/application-route-mixin'], function (exports, _ember, _simpleAuthMixinsApplicationRouteMixin) {
  var ApplicationRoute;

  ApplicationRoute = _ember['default'].Route.extend(_simpleAuthMixinsApplicationRouteMixin['default'], {
    title: function title(tokens) {
      return tokens.join(' - ') + ' | Dispel';
    },
    beforeModel: function beforeModel(transition) {
      this._super(transition);
      if (this.session.isAuthenticated) {
        return this.populateCurrentUser().then((function (_this) {
          return function (user) {
            return _this.startIntercom();
          };
        })(this));
      }
    },
    populateCurrentUser: function populateCurrentUser() {
      var _currentUser, _currentUserBase64, err, user_id;
      _currentUserBase64 = this.get('session.secure.currentUser');
      try {
        _currentUser = JSON.parse(atob(_currentUserBase64));
      } catch (_error) {
        err = _error;
        Raven.captureException(err);
        this.transitionTo('login');
        return false;
      }
      user_id = _currentUser.id;
      return this.store.findRecord('user', user_id).then((function (_this) {
        return function (user) {
          user.set('intercom_hash', _currentUser.intercom_hash);
          return _this.get('currentUser').set('content', user);
        };
      })(this));
    },
    startIntercom: function startIntercom() {
      return window.Intercom("boot", {
        app_id: this.config.Variables.INTERCOM_APP_ID,
        user_id: this.get('currentUser.id'),
        email: this.get('currentUser.email'),
        user_hash: this.get('currentUser.intercom_hash'),
        created_at: Math.floor(Date.parse(this.get('currentUser.created_at')) / 1000)
      });
    },
    stopIntercom: function stopIntercom() {
      return window.Intercom('shutdown');
    },
    setSentryContext: function setSentryContext() {
      return Raven.setUserContext({
        email: this.get('currentUser.email'),
        id: this.get('currentUser.id')
      });
    },
    removeSentryContext: function removeSentryContext() {
      return Raven.setUserContext();
    },
    actions: {
      authorizationFailed: function authorizationFailed(event) {
        var _message;
        if (this.get('session').get('isAuthenticated')) {
          _message = "Your session has expired for security reasons.<br>Please sign back in.";
          Materialize.toast(_message, 4500, 'light-blue');
          return this.get('session').invalidate();
        }
      },
      sessionAuthenticationSucceeded: function sessionAuthenticationSucceeded() {
        swal.close();
        return this.populateCurrentUser().then((function (_this) {
          return function (user) {
            var _message;
            _message = "Welcome back " + _this.get('currentUser.email');
            Materialize.toast(_message, 4500, 'green');
            _this.startIntercom();
            _this.setSentryContext();
            return _this.transitionTo('index');
          };
        })(this));
      },
      sessionInvalidationSucceeded: function sessionInvalidationSucceeded() {
        Materialize.toast("Logged out. Goodbye.", 4500);
        this.removeSentryContext();
        this.stopIntercom();
        return this.transitionTo('login');
      },
      sessionAuthenticationFailed: function sessionAuthenticationFailed(err) {
        var _message;
        _message = "Sorry, you entered the wrong email or password,<br> the account does not exist, or the account has not been validated.";
        Materialize.toast(_message, 6500, 'red');
        this.transitionTo('login');
        return swal.close();
      }
    }
  });

  exports['default'] = ApplicationRoute;
});