define('dispel-client/helpers/minutesto-hours', ['exports', 'ember'], function (exports, _ember) {
  var MinutestoHoursHelper, minutestoHours;

  exports.minutestoHours = minutestoHours = function (params) {
    var _hours;
    _hours = Math.floor(params[0] / 60);
    return _hours;
  };

  MinutestoHoursHelper = _ember['default'].Helper.helper(minutestoHours);

  exports.minutestoHours = minutestoHours;
  exports['default'] = MinutestoHoursHelper;
});