define('dispel-client/features/billing/index/route', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin) {
  var BillingIndexRoute;

  BillingIndexRoute = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], {
    redirect: function redirect(model, transition) {
      if (transition.targetName === 'billing.index') {
        return this.transitionTo('billing.overview');
      }
    }
  });

  exports['default'] = BillingIndexRoute;
});