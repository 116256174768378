define('dispel-client/instance-initializers/current-user', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = {
    name: "current-user",

    initialize: function initialize(_ref) {
      var registry = _ref.registry;

      var service = _ember['default'].ObjectProxy.create({ isServiceFactory: true });
      registry.register('service:current-user', service, { instantiate: false, singleton: true });
      registry.injection('route', 'currentUser', 'service:current-user');
      registry.injection('controller', 'currentUser', 'service:current-user');
      registry.injection('component', 'currentUser', 'service:current-user');
    }
  };
});