define('dispel-client/features/billing/overview/route', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin) {
  var BillingOverviewRoute;

  BillingOverviewRoute = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], {
    titleToken: 'Overview',
    renderTemplate: function renderTemplate() {
      return this.render({
        into: 'billing',
        outlet: 'overview'
      });
    },
    model: function model() {
      return _ember['default'].RSVP.hash({
        user: this.store.peekRecord('user', this.get('currentUser.id')),
        plans: this.store.peekAll('subscription'),
        subscription: this.store.peekRecord('subscription', this.get('currentUser.subscription_id')),
        receiptTypes: this.store.peekAll('receipt-type'),
        receipts: this.store.query('receipt', {
          user_id: this.get('currentUser.id')
        }),
        cards: this.store.query('credit-card', {
          user_id: this.get('currentUser.id')
        })
      });
    }
  });

  exports['default'] = BillingOverviewRoute;
});