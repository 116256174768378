define('dispel-client/helpers/receipt-date', ['exports', 'ember'], function (exports, _ember) {
  var ReceiptDateHelper, receiptDate;

  exports.receiptDate = receiptDate = function (params) {
    var date, formatted_date;
    date = params[0];
    formatted_date = moment(date).format('LL');
    return formatted_date;
  };

  ReceiptDateHelper = _ember['default'].HTMLBars.makeBoundHelper(receiptDate);

  exports.receiptDate = receiptDate;
  exports['default'] = ReceiptDateHelper;
});