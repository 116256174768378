define('dispel-client/helpers/subscription-name', ['exports', 'ember'], function (exports, _ember) {
  var SubscriptionNameHelper, subscriptionName;

  exports.subscriptionName = subscriptionName = function (params) {
    var name, new_name, new_subscription_id, old_name, old_subscription_id, receiptName, receiptTypes, receipt_type_id, subscription_id, subscriptions;
    receipt_type_id = params[3].toString();
    receiptTypes = params[4];
    receiptName = receiptTypes.findBy('id', receipt_type_id).get('type');
    if (receiptName === "Upgrade" || receiptName === "Downgrade") {
      old_subscription_id = params[0].toString();
      new_subscription_id = params[1].toString();
      subscriptions = params[2];
      if (old_subscription_id) {
        old_name = subscriptions.findBy('id', old_subscription_id).get('name');
        new_name = subscriptions.findBy('id', new_subscription_id).get('name');
        return _ember['default'].String.htmlSafe("- " + old_name + " Plan " + ' <i class="tiny subscription-icon material-icons">arrow_forward</i> ' + new_name + " Plan");
      }
    } else if (receiptName === "Monthly Charge") {
      if (params[0]) {
        subscription_id = params[1].toString();
        subscriptions = params[2];
        name = subscriptions.findBy('id', subscription_id).get('name');
        return "- " + name + " Plan";
      } else {
        return "Placeholder";
      }
    }
  };

  SubscriptionNameHelper = _ember['default'].HTMLBars.makeBoundHelper(subscriptionName);

  exports.subscriptionName = subscriptionName;
  exports['default'] = SubscriptionNameHelper;
});