define('dispel-client/features/account/settings/route', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin) {
  var AccountSettingsRoute;

  AccountSettingsRoute = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], {
    renderTemplate: function renderTemplate() {
      return this.render({
        into: 'account',
        outlet: 'settings'
      });
    }
  });

  exports['default'] = AccountSettingsRoute;
});