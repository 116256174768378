define('dispel-client/initializers/simple-auth-token', ['exports', 'simple-auth-token/authenticators/token', 'simple-auth-token/authenticators/jwt', 'simple-auth-token/authorizers/token', 'simple-auth-token/configuration', 'dispel-client/config/environment'], function (exports, _simpleAuthTokenAuthenticatorsToken, _simpleAuthTokenAuthenticatorsJwt, _simpleAuthTokenAuthorizersToken, _simpleAuthTokenConfiguration, _dispelClientConfigEnvironment) {

  /**
    Ember Simple Auth Token's Initializer.
    By default load both the Token and JWT (with refresh) Authenticators.
  */
  exports['default'] = {
    name: 'simple-auth-token',
    before: 'simple-auth',
    initialize: function initialize(container) {
      _simpleAuthTokenConfiguration['default'].load(container, _dispelClientConfigEnvironment['default']['simple-auth-token'] || {});
      container.register('simple-auth-authorizer:token', _simpleAuthTokenAuthorizersToken['default']);
      container.register('simple-auth-authenticator:token', _simpleAuthTokenAuthenticatorsToken['default']);
      container.register('simple-auth-authenticator:jwt', _simpleAuthTokenAuthenticatorsJwt['default']);
    }
  };
});