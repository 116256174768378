define('dispel-client/transitions', ['exports', 'ember'], function (exports, _ember) {
  ;
  var appTransitions, routeFunction;

  routeFunction = function (route) {
    if (route !== 'login') {
      return route;
    }
  };

  appTransitions = function () {
    if (_ember['default'].testing) {
      this.setDefault({
        duration: 10
      });
    }
    this.transition(this.hasClass('login-signup'), this.toValue(true), this.use('fade', {
      duration: 300
    }), this.reverse('fade', {
      duration: 450
    }));
    this.transition(this.fromRoute('service'), this.toRoute('account'), this.use('fade', {
      duration: 200
    }), this.reverse('fade', {
      duration: 200
    }));
    this.transition(this.fromRoute('service'), this.toRoute('billing'), this.use('fade', {
      duration: 200
    }), this.reverse('fade', {
      duration: 200
    }));
    this.transition(this.fromRoute('account'), this.toRoute('billing'), this.use('fade', {
      duration: 200
    }), this.reverse('fade', {
      duration: 200
    }));
    this.transition(this.fromRoute('account'), this.toRoute('service'), this.use('fade', {
      duration: 200
    }), this.reverse('fade', {
      duration: 200
    }));
    this.transition(this.fromRoute('billing'), this.toRoute('service'), this.use('fade', {
      duration: 200
    }), this.reverse('fade', {
      duration: 200
    }));
    this.transition(this.fromRoute('billing'), this.toRoute('account'), this.use('fade', {
      duration: 200
    }), this.reverse('fade', {
      duration: 200
    }));
  };

  exports['default'] = appTransitions;
});