define('dispel-client/helpers/lastfour-digits', ['exports', 'ember'], function (exports, _ember) {
  var LastfourDigitsHelper, lastfourDigits;

  exports.lastfourDigits = lastfourDigits = function (params) {
    var _lastfourDigits, _regex;
    _regex = /.{4}$/;
    _lastfourDigits = _regex.exec(params[0]);
    return _lastfourDigits;
  };

  LastfourDigitsHelper = _ember['default'].Helper.helper(lastfourDigits);

  exports.lastfourDigits = lastfourDigits;
  exports['default'] = LastfourDigitsHelper;
});