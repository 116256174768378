define('dispel-client/features/components/signup-form/component', ['exports', 'ember'], function (exports, _ember) {
  var SignupFormComponent;

  SignupFormComponent = _ember['default'].Component.extend({
    $form: void 0,
    $email: void 0,
    $password: void 0,
    $confirmPassword: void 0,
    $coupon: void 0,
    $tabs: void 0,
    validEmail: false,
    validPassword: false,
    validConfirmPassword: false,
    validCoupon: false,
    buttonStatus: 'disabled',
    credentialsTabAvailable: true,
    planTabAvailable: 'disabled',
    paymentTabAvailable: 'disabled',
    doneTabAvailable: 'disabled',
    credentialsTabComplete: false,
    planTabComplete: false,
    paymentTabComplete: false,
    doneTabComplete: false,
    subscriptionName: '',
    subscriptionPrice: 0,
    validatedCredentials: false,
    subscriptionChosen: false,
    credentialsButtonStatus: 'disabled',
    credentialsButtonBool: 'false',
    couponButtonStatus: 'disabled',
    planButtonStatus: 'disabled',
    doneButtonStatus: 'signup--btn--robinBlue signup--btn',
    redeemingCoupon: false,
    setVariables: (function () {
      this.set('$form', $('form'));
      this.set('$email', this.$form.find('.signup--email'));
      this.set('$password', this.$form.find('.signup--password'));
      this.set('$confirmPassword', this.$form.find('.signup--confirm-password'));
      this.set('$tabs', $('ul.tabs'));
      return this.parsley();
    }).on('didInsertElement'),
    parsley: function parsley() {
      this.setCredentialsValidation();
      return this.$form.parsley();
    },
    currentStep: 0,
    signupStepNames: {
      credentials: {
        position: 0,
        domObject: void 0
      },
      plan: {
        position: 1,
        domObject: void 0
      },
      payment: {
        position: 2,
        domObject: void 0
      },
      done: {
        position: 3,
        domObject: void 0
      },
      verification: {
        position: 4,
        domObject: void 0
      }
    },
    setSignupNames: (function () {
      var _route, key, ref, value;
      ref = this.get('signupStepNames');
      for (key in ref) {
        value = ref[key];
        _route = 'signup/' + key;
        this.signupSteps.push(_route);
      }
    }).on('init'),
    setSignupDomElements: (function () {
      var _domQuery, key, ref, results, value;
      ref = this.get('signupStepNames');
      results = [];
      for (key in ref) {
        value = ref[key];
        _domQuery = '[signup-step=' + value.position + ']';
        results.push(value.domObject = $(_domQuery));
      }
      return results;
    }).on('didInsertElement'),
    signupSteps: [],
    signupStep: (function () {
      return this.signupSteps[this.get('currentStep')];
    }).property('currentStep'),
    validateToken: function validateToken() {
      var _onFail, _onSuccess, _verificationToken, _verifyTokenRequest;
      _verificationToken = {
        verification_token: this.get('verification').replace(/ /g, '+')
      };
      _verifyTokenRequest = _ember['default'].$.ajax({
        url: this.config.Variables.API_URL + '/validate-token/',
        method: 'post',
        data: _verificationToken
      });
      _verifyTokenRequest.done((function (_this) {
        return function (_response) {
          return _onSuccess(_response);
        };
      })(this));
      _verifyTokenRequest.error((function (_this) {
        return function (_response) {
          return _onFail(_response);
        };
      })(this));
      _onSuccess = (function (_this) {
        return function (response) {
          return swal({
            title: response.email + ' verified',
            imageUrl: "/assets/animations/imac-loop.gif",
            text: 'Please enter your password to sign in and get started:',
            type: 'input',
            confirmButtonText: 'Sign in',
            inputType: 'password',
            closeOnConfirm: false,
            animation: true,
            inputPlaceholder: 'Your password'
          }, function (inputValue) {
            var authenticator, data;
            if (inputValue === false) {
              return false;
            }
            if (inputValue === '') {
              swal.showInputError('Please enter your password.');
              return false;
            }
            swal({
              title: 'Signing you in now',
              imageUrl: "/assets/animations/atom-loop.gif",
              html: true,
              allowEscapeKey: false,
              allowOutsideClick: false,
              closeOnConfirm: false,
              showConfirmButton: false
            });
            Materialize.toast("Signing you in <i class='material-icons rotating'>autorenew</i>", 2500);
            data = {
              identification: response.email,
              password: inputValue
            };
            authenticator = 'simple-auth-authenticator:token';
            _this.get('session').authenticate(authenticator, data);
          });
        };
      })(this);
      return _onFail = function (error) {
        swal({
          title: 'Verification failed',
          text: "<p>We couldn't verify your email using this token.</p><p>If you believe this was a mistake on our part, please <a href='mailto:support@dispel.io'>contact us</a>.</p>",
          type: 'error',
          html: true,
          closeOnConfirm: false
        });
        return Raven.captureException(error, {
          extra: {
            message: "Signup verification failed"
          }
        });
      };
    },
    verificationWatcher: (function () {
      if (!this.get('session').isAuthenticated) {
        if (this.get('verification')) {
          this.send('goToStep', 'verification');
          this.validateToken();
          return swal({
            title: 'Verifying your email',
            text: "<p>Thank you for confirming your email.</p><p>Give us a moment while we check things out.</p>",
            imageUrl: "/assets/animations/console-loop.gif",
            html: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            closeOnConfirm: false,
            showConfirmButton: false
          });
        }
      }
    }).on('didInsertElement'),
    redeemingCouponWatcher: (function () {
      if (this.get('redeemingCoupon')) {
        return setTimeout((function (_this) {
          return function () {
            _this.set('$coupon', _this.$form.find('.signup--coupon'));
            _this.$coupon.parsley().on('field:success', function (event, formInstance) {
              return _this.set('validCoupon', true);
            });
            _this.$coupon.parsley().on('field:error', function (event, formInstance) {
              return _this.set('validCoupon', false);
            });
          };
        })(this), 100);
      }
    }).observes('redeemingCoupon'),
    setCredentialsValidation: function setCredentialsValidation() {
      this.$email.parsley().on('field:success', (function (_this) {
        return function (event, formInstance) {
          return _this.set('validEmail', true);
        };
      })(this));
      this.$email.parsley().on('field:error', (function (_this) {
        return function (event, formInstance) {
          return _this.set('validEmail', false);
        };
      })(this));
      this.$password.parsley().on('field:success', (function (_this) {
        return function (event, formInstance) {
          return _this.set('validPassword', true);
        };
      })(this));
      this.$password.parsley().on('field:error', (function (_this) {
        return function (event, formInstance) {
          return _this.set('validPassword', false);
        };
      })(this));
      this.$confirmPassword.parsley().on('field:success', (function (_this) {
        return function (event, formInstance) {
          if (_this.$password.val() === _this.$confirmPassword.val()) {
            return _this.set('validConfirmPassword', true);
          } else {
            return _this.set('validConfirmPassword', false);
          }
        };
      })(this));
      return this.$confirmPassword.parsley().on('field:error', (function (_this) {
        return function (event, formInstance) {
          return _this.set('validConfirmPassword', false);
        };
      })(this));
    },
    credentialsSubmittable: (function () {
      if (this.validEmail && this.validPassword && this.validConfirmPassword && this.validCoupon) {
        Materialize.toast("Redeem Free Trial.", 3000);
        this.set('couponButtonStatus', 'signup--btn--robinBlue signup--btn');
        this.set('validatedCredentials', true);
        return true;
      } else if (this.validEmail && this.validPassword && this.validConfirmPassword) {
        Materialize.toast("Passwords match.", 3000);
        this.set('credentialsButtonStatus', 'signup--btn--robinBlue signup--btn');
        this.set('credentialsButtonBool', true);
        this.set('validatedCredentials', true);
        return true;
      } else {
        this.set('credentialsButtonStatus', 'disabled');
        this.set('credentialsButtonBool', false);
        this.set('validatedCredentials', false);
        return false;
      }
    }).observes('validPassword', 'validEmail', 'validConfirmPassword', 'validCoupon'),
    sortedPlans: (function () {
      return _ember['default'].ArrayProxy.extend(_ember['default'].SortableMixin).create({
        sortProperties: ['monthly_price'],
        sortAscending: true,
        content: this.get('model.plans')
      });
    }).property('plans'),
    formatAndCleanPlans: (function () {
      var _plans, _user, _user_subscription;
      _user = this.get('model.user');
      _user_subscription = this.get('model.subscription');
      _plans = this.get('model.plans');
      return _plans.forEach((function (_this) {
        return function (value, index) {
          var _monthly_price;
          _monthly_price = value.get('monthly_price');
          value.set('monthly_price', parseInt(_monthly_price));
          if (_monthly_price > 0) {
            return value.set('show', true);
          } else if (value.get('name') === 'Trial') {
            return value.set('show', true);
          }
        };
      })(this));
    }).on('willInsertElement'),
    planEnabled: function planEnabled() {
      var _plans, _user, _userSubscription;
      _user = this.get('model.user');
      _userSubscription = this.get('model.subscription');
      _plans = this.get('model.plans');
      this.set('subscriptionChosen', false);
      return _plans.forEach((function (_this) {
        return function (value, index) {
          if (value.id === _userSubscription.id) {
            value.set('enabled', true);
            return _this.set('subscriptionChosen', true);
          } else {
            return value.set('enabled', false);
          }
        };
      })(this));
    },
    checkIfPlanEnabled: (function () {
      return this.planEnabled();
    }).on('didInsertElement'),
    planSubmittable: (function () {
      if (this.subscriptionChosen) {
        Materialize.toast("Plan selected.", 3000);
        this.set('subscriptionChosen', true);
        this.set('planButtonStatus', 'signup--btn--robinBlue signup--btn');
        this.notifyPropertyChange('subscriptionName');
        return true;
      } else {
        this.set('subscriptionChosen', false);
        this.set('planButtonStatus', 'disabled');
        this.notifyPropertyChange('subscriptionName');
        return false;
      }
    }).observes('subscriptionChosen'),
    subscriptionName: (function () {
      var _plan;
      _plan = this.get('model.plans').findBy('id', this.get('model.user.subscription_id'));
      return _plan.get('name');
    }).property('subscriptionName'),
    subscriptionPrice: (function () {
      var _plan;
      _plan = this.get('model.plans').findBy('id', this.get('model.user.subscription_id'));
      return parseInt(_plan.get('monthly_price'));
    }).property('subscriptionPrice'),
    selectedTrial: (function () {
      if (this.get('subscriptionName') === 'Trial') {
        return true;
      } else {
        return false;
      }
    }).property('subscriptionName'),
    cardAdded: (function () {
      if (this.get('model.user.pending_credit_card')) {
        this.set('paymentTabAvailable', true);
        return this.send('advanceStep');
      }
    }).observes('model.user.pending_credit_card'),
    actions: {
      openModal: function openModal() {
        var _targetModal;
        _targetModal = $(event.target).attr('href');
        return $(_targetModal).openModal();
      },
      signup: function signup() {
        if (this.validatedCredentials) {
          return this.send('advanceStep');
        } else {
          Materialize.toast("Please enter the necessary information to get started.", 4000);
          return this.$email.focus();
        }
      },
      setPlan: function setPlan() {
        if (this.subscriptionChosen) {
          return this.send('advanceStep');
        }
      },
      advanceStep: function advanceStep() {
        var _newStep, _oldStep;
        if (this.currentStep < this.get('signupSteps').length) {
          _oldStep = this.signupSteps[this.currentStep].replace('signup/', '');
          this.incrementProperty('currentStep');
          _newStep = this.signupSteps[this.currentStep].replace('signup/', '');
          this.set(_oldStep + "TabAvailable", 'signup--tab--complete');
          this.set(_oldStep + "TabComplete", true);
          this.set(_newStep + "TabAvailable", true);
          return setTimeout((function (_this) {
            return function () {
              _this.$tabs.tabs('select_tab', _newStep);
            };
          })(this), 100);
        }
      },
      goToStep: function goToStep(signupStep, type) {
        var _step, _tabAvailable;
        _tabAvailable = this.get(signupStep + 'TabAvailable');
        if (_tabAvailable !== 'disabled') {
          _step = this.signupStepNames[signupStep];
          this.set('currentStep', _step.position);
          if (type === 'manual') {
            return setTimeout((function (_this) {
              return function () {
                _this.$tabs.tabs('select_tab', signupStep);
              };
            })(this), 100);
          }
        }
      },
      showCreditInformation: function showCreditInformation() {
        return swal({
          title: "What happens if I run over?",
          text: "<p>If you use up your monthly credits, we'll buy you credits in $10 increments to deduct against. Any leftovers will be credited against next month's subscription price.</p><p>If we don't have your permission to buy more credits, we'll have to shut down your service till next month.</p><p>Not to worry, we'll notify you before you run out.</p>",
          html: true
        });
      },
      choosePlan: function choosePlan(plan, user, subscription) {
        this.set('model.user.subscription_signup_date', new Date());
        if (user.subscription_id !== null) {
          this.set('model.user.subscription_id', plan.id);
          this.set('model.subscription', plan);
          return this.planEnabled();
        }
      },
      toggleCreditCharge: function toggleCreditCharge(user) {
        var _bool;
        _bool = user.get('overage_autocharge_permission');
        _bool = !_bool;
        this.set('model.user.overage_autocharge_permission', _bool);
        if (this.get('model.user.overage_autocharge_permission')) {
          return Materialize.toast("<i class='material-icons tiny'>check_circle</i> You'll enjoy uninterrupted service if you go over.", 4000, 'light-blue');
        } else {
          return Materialize.toast("<i class='material-icons tiny'>warning</i> If you go over your plan's limits,<br>you will lose service until next billing cycle.", 4000, 'amber black-text');
        }
      },
      editEmail: function editEmail() {
        return this.send('goToStep', 'credentials', 'manual');
      },
      editPlan: function editPlan() {
        return this.send('goToStep', 'plan', 'manual');
      },
      editPayment: function editPayment() {
        return this.send('goToStep', 'payment', 'manual');
      },
      completeSignup: function completeSignup() {
        var $_doneButton, _newUser, _onFail, _onSuccess, _user;
        _user = this.get('model.user');
        if (_user.get('password') !== _user.get('password_confirmation')) {
          Materialize.toast("Your password and password confirmation do not match.", 4000, 'amber');
          return this.send('goToStep', 'credentials');
        } else {
          this.set('model.user.accepted_terms_of_service', true);
          this.set('model.user.accepted_privacy_policy', true);
          $_doneButton = $('.signup--done--submit');
          if (_user.get('isNew') && _user.get('hasDirtyAttributes')) {
            $_doneButton.html('');
            $_doneButton.addClass('btn-loading');
            Materialize.toast("<i class='material-icons rotating'>autorenew</i> Submitting your new account information", 4000, 'light-blue');
            _newUser = {
              email: _user.get('email'),
              password: _user.get('password'),
              password_confirmation: _user.get('password_confirmation'),
              accepted_terms_of_service: _user.get('accepted_terms_of_service'),
              accepted_privacy_policy: _user.get('accepted_privacy_policy'),
              subscription_id: _user.get('subscription_id'),
              overage_autocharge_permission: _user.get('overage_autocharge_permission'),
              pending_credit_card: _user.get('pending_credit_card'),
              prior_coupon: 'freeTrial'
            };
            _ember['default'].$.post(this.config.Variables.API_URL + '/signup/', _newUser).then(function (response) {
              _onSuccess(response);
            }, function (error) {
              _onFail();
            });
            _onSuccess = (function (_this) {
              return function (response) {
                Materialize.toast("<i class='material-icons'>check_circle</i> Account registered!", 4000, 'green');
                return _this.send('advanceStep');
              };
            })(this);
            return _onFail = (function (_this) {
              return function (returnedObj) {
                Materialize.toast("Our servers failed to register this change.<br>With deep self-loathing, we apologize and ask forgiveness.<br>Please  try again later, and let us know.", 4000, 'red');
                return Raven.captureException(new Error('Signup verification failed'), {
                  extra: {
                    returnedObj: returnedObj
                  }
                });
              };
            })(this);
          }
        }
      },
      toggleFreeTrial: function toggleFreeTrial() {
        return this.toggleProperty('redeemingCoupon');
      }
    }
  });

  exports['default'] = SignupFormComponent;
});