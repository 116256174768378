define('dispel-client/features/signup/verify/controller', ['exports', 'ember'], function (exports, _ember) {
  var SignupVerifyController;

  SignupVerifyController = _ember['default'].Controller.extend({
    verificationWatcher: _ember['default'].observer('verification', function () {
      if (!this.get('session').isAuthenticated) {
        if (this.get('verification')) {
          this.validateToken();
          return swal({
            title: 'Verifying your email',
            text: "<p>Thank you for confirming your email.</p><p>Give us a moment while we check things out.</p>",
            imageUrl: "/assets/animations/console-loop.gif",
            html: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            closeOnConfirm: false,
            showConfirmButton: false
          });
        }
      }
    }),
    validateToken: function validateToken() {
      var _, _onFail, _onSuccess, _verification, _verificationToken, _verifyTokenRequest, i, numberOfPaddingToAdd, padding, ref, rest;
      _verification = this.get('verification').replace(/ /g, '+');
      rest = _verification.length % 4;
      padding = "";
      if (rest !== 0) {
        numberOfPaddingToAdd = 4 - rest;
        for (_ = i = 0, ref = numberOfPaddingToAdd; 0 <= ref ? i < ref : i > ref; _ = 0 <= ref ? ++i : --i) {
          padding += "=";
        }
      }
      _verification += padding;
      _verificationToken = {
        verification_token: _verification
      };
      _verifyTokenRequest = _ember['default'].$.ajax({
        url: this.config.Variables.API_URL + '/validate-token/',
        method: 'post',
        data: _verificationToken
      });
      _verifyTokenRequest.done((function (_this) {
        return function (_response) {
          return _onSuccess(_response);
        };
      })(this));
      _verifyTokenRequest.error((function (_this) {
        return function (_response) {
          return _onFail(_response);
        };
      })(this));
      _onSuccess = (function (_this) {
        return function (response) {
          var _user, authenticator, data;
          _user = _this.get('model.user');
          if (_user.password) {
            swal({
              title: 'Verification succeded!',
              text: "Thank you for confirming your email address!",
              imageUrl: "/assets/animations/atom-loop.gif",
              type: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              closeOnConfirm: false,
              showConfirmButton: false
            });
            Materialize.toast("Signing you in <i class='material-icons rotating'>autorenew</i>", 2500);
            data = {
              identification: response.email,
              password: _user.password
            };
            authenticator = 'simple-auth-authenticator:token';
            return _this.get('session').authenticate(authenticator, data);
          } else {
            return swal({
              title: response.email + ' verified',
              imageUrl: "/assets/animations/imac-loop.gif",
              text: 'Please enter your password to sign in and get started:',
              type: 'input',
              confirmButtonText: 'Sign in',
              inputType: 'password',
              closeOnConfirm: false,
              animation: true,
              inputPlaceholder: 'Your password'
            }, function (inputValue) {
              if (inputValue === false) {
                return false;
              }
              if (inputValue === '') {
                swal.showInputError('Please enter your password.');
                return false;
              }
              swal({
                title: 'Signing you in now',
                imageUrl: "/assets/animations/atom-loop.gif",
                html: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                closeOnConfirm: false,
                showConfirmButton: false
              });
              Materialize.toast("Signing you in <i class='material-icons rotating'>autorenew</i>", 2500);
              data = {
                identification: response.email,
                password: inputValue
              };
              authenticator = 'simple-auth-authenticator:token';
              _this.get('session').authenticate(authenticator, data);
            });
          }
        };
      })(this);
      return _onFail = function (error) {
        swal({
          title: 'Verification failed',
          text: "<p>We couldn't verify your email using this token.</p><p>If you believe this was a mistake on our part, please <a href='mailto:support@dispel.io'>contact us</a>.</p>",
          type: 'error',
          html: true,
          closeOnConfirm: false
        });
        return Raven.captureException(error, {
          extra: {
            message: "Signup verification failed"
          }
        });
      };
    }
  });

  exports['default'] = SignupVerifyController;
});