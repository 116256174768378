define('dispel-client/models/credit-card', ['exports', 'ember-data'], function (exports, _emberData) {
  var CreditCard;

  CreditCard = _emberData['default'].Model.extend({
    credit_card_id: _emberData['default'].attr('string'),
    payer_id: _emberData['default'].attr('string'),
    last_four: _emberData['default'].attr('string'),
    create_time: _emberData['default'].attr('date'),
    update_time: _emberData['default'].attr('date'),
    type: _emberData['default'].attr('string'),
    expire_month: _emberData['default'].attr('string'),
    expire_year: _emberData['default'].attr('string'),
    card_state: _emberData['default'].attr('string'),
    first_name: _emberData['default'].attr('string'),
    last_name: _emberData['default'].attr('string'),
    primary_card: _emberData['default'].attr('boolean'),
    user: _emberData['default'].belongsTo('user', {
      async: true
    }),
    payment: _emberData['default'].hasMany('payment', {
      async: true
    })
  });

  exports['default'] = CreditCard;
});