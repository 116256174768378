define('dispel-client/features/signup/credentials/controller', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
  var SignupCredentialsController;

  SignupCredentialsController = _ember['default'].Controller.extend(_emberValidations['default'], {
    signup: _ember['default'].inject.controller(),
    queryParams: ['referredFrom', 'linkedPlan'],
    referredFrom: null,
    linkedPlan: null,
    credentialsTabComplete: _ember['default'].computed.alias('signup.credentialsTabComplete'),
    credentialsTabAvailable: _ember['default'].computed.alias('signup.credentialsTabAvailable'),
    planTabAvailable: _ember['default'].computed.alias('signup.planTabAvailable'),
    credentialsButtonStatus: 'disabled',
    email: void 0,
    emailError: '',
    password: void 0,
    passwordError: '',
    confirmPassword: void 0,
    confirmPasswordError: '',
    validations: {
      email: {
        email: true
      },
      password: {
        password: true
      },
      confirmPassword: {
        match: {
          first: 'password',
          second: 'confirmPassword'
        }
      }
    },
    validateInputs: _ember['default'].observer('email', 'password', 'confirmPassword', function () {
      var _confirm, _confirmErr, _email, _emailErr, _passErr, _password;
      _email = this.get('email');
      _emailErr = this.get('errors.email');
      _password = this.get('password');
      _passErr = this.get('errors.password');
      _confirm = this.get('confirmPassword');
      _confirmErr = this.get('errors.confirmPassword');
      if (_email && _email.length) {
        this.set('emailError', _emailErr[0]);
      }
      if (_password && _password.length) {
        this.set('passwordError', _passErr[0]);
      }
      if (_confirm && _confirm.length) {
        this.set('confirmPasswordError', _confirmErr[0]);
      }
      if (!_emailErr.length && _email.length && !_passErr.length && _password.length && !_confirmErr.length && _confirm) {
        return this.set('credentialsButtonStatus', '');
      } else {
        return this.set('credentialsButtonStatus', 'disabled');
      }
    }),
    actions: {
      completeCredentials: function completeCredentials() {
        var _noPlan, _usersLinkedPlan;
        if (this.get('credentialsButtonStatus') === 'disabled') {
          return false;
        }
        if (this.get('linkedPlan') != null) {
          _usersLinkedPlan = this.get('model.plans').findBy('name', this.get('linkedPlan').capitalize());
        } else {
          _noPlan = this.get('model.plans').findBy('name', "No-Plan");
          this.set('model.user.subscription_id', _noPlan.id);
        }
        this.set('model.user.email', this.get('email'));
        this.set('model.user.password', this.get('password'));
        this.set('model.user.password_confirmation', this.get('confirmPassword'));
        this.set('model.user.referred_from', this.get('referredFrom'));
        if (_usersLinkedPlan != null) {
          this.set('model.user.subscription_id', parseInt(_usersLinkedPlan.id));
        }
        this.set('credentialsTabComplete', true);
        this.set('planTabAvailable', '');
        this.set('credentialsTabAvailable', '');
        return _ember['default'].run.next(function () {
          _ember['default'].$('#plans').click();
          return this.transitionToRoute('signup.plans');
        });
      }
    }
  });

  exports['default'] = SignupCredentialsController;
});