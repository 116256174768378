define('dispel-client/features/service/controller', ['exports', 'ember'], function (exports, _ember) {
  var ServiceController;

  ServiceController = _ember['default'].Controller.extend({
    pageName: 'Mission Control',
    trialSubscription: false,
    urlPrefix: (function () {
      return this.config.Variables.API_URL + '/' + this.config.Variables.URL_NAMESPACE;
    }).property(),
    trialSubscription: (function () {
      var _subscription;
      _subscription = this.get('model.subscription');
      if (_subscription.get('name') === 'Trial') {
        return true;
      } else {
        return false;
      }
    }).property('model.subscription'),
    actions: {
      resolveBill: function resolveBill() {
        return swal({
          title: "Resolve Overage Charge",
          text: "Your account will be charged $10 for the overage credit. Don't worry. If you do not use the whole credit, you will receive a discount on next month's bill.",
          imageUrl: "/assets/images/credit_cards/bill.svg",
          showCancelButton: true,
          confirmButtonColor: '#08bcff',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Nevermind',
          closeOnConfirm: false,
          closeOnCancel: true,
          showLoaderOnConfirm: true,
          allowOutsideClick: true
        }, (function (_this) {
          return function (isConfirm) {
            var _onFail, _onSuccess;
            if (isConfirm) {
              swal({
                title: 'Processing your payment',
                text: "<p>Please wait a moment while we process your payment.</p>",
                imageUrl: "/assets/animations/console-loop.gif",
                html: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                closeOnConfirm: false,
                showConfirmButton: false
              });
              _ember['default'].$.post(_this.get('urlPrefix') + '/resolve-bill').then(function (response, textStatus, xhr) {
                _onSuccess(response, textStatus, xhr);
              }, function (error) {
                _onFail();
              });
              _onSuccess = function (response, textStatus, xhr) {
                response = JSON.parse(response);
                _this.send('resetModel');
                if (response.status === 200) {
                  swal("Success!", "Your ready to get back online", "success");
                  return _this.transitionTo('service');
                } else if (response.status === 403) {
                  return swal("Oops!", "You do not have an active credit card, please add a credit card and try again.", "error");
                } else if (response.status === 405) {
                  return swal("Oops!", "Your credit card has expired, use a different card and try again.", "error");
                } else if (response.status === 407) {
                  return swal({
                    title: "Oops!",
                    text: "Your payment failed to process, please <a href='mailto:support@dispel.io'>contact us</a> to resolve the issue.",
                    type: "error",
                    html: true
                  });
                }
              };
              return _onFail = function (returnedObj) {
                return swal({
                  title: "Oh no!",
                  text: "Our servers failed to resolve your bill. With deep self-loathing, we apologize and ask forgiveness. Please <a href='mailto:support@dispel.io'>contact us</a>.",
                  type: "error",
                  html: true
                });
              };
            }
          };
        })(this));
      }
    }
  });

  exports['default'] = ServiceController;
});