define('dispel-client/helpers/receipt-credited', ['exports', 'ember'], function (exports, _ember) {
  var ReceiptCreditedHelper, receiptCredited;

  exports.receiptCredited = receiptCredited = function (params) {
    var amount, credit_applied, monthly_price, new_price, new_subscription_id, nothing, old_price, old_subscription_id, prorated_amount, receiptName, receiptTypes, receipt_type_id, subscription_id, subscriptions;
    nothing = void 0;
    receipt_type_id = params[0].toString();
    receiptTypes = params[1];
    amount = params[3];
    subscriptions = params[6];
    receiptName = receiptTypes.findBy('id', receipt_type_id).get("type");
    if (receiptName === "Upgrade") {
      old_subscription_id = params[4].toString();
      new_subscription_id = params[5].toString();
      old_price = parseFloat(subscriptions.findBy('id', old_subscription_id).get('monthly_price'));
      new_price = parseFloat(subscriptions.findBy('id', new_subscription_id).get('monthly_price'));
      prorated_amount = new_price - old_price - amount;
      if (prorated_amount > 0) {
        return "Prorated $" + prorated_amount.toString();
      } else {
        return nothing;
      }
    } else if (receiptName === "Monthly Charge") {
      subscription_id = params[5].toString();
      monthly_price = parseFloat(subscriptions.findBy('id', subscription_id).get("monthly_price"));
      credit_applied = Math.round(100 * (monthly_price - amount)) / 100;
      if (credit_applied > 0) {
        return "Credited $" + credit_applied.toString();
      } else {
        return nothing;
      }
    }
  };

  ReceiptCreditedHelper = _ember['default'].HTMLBars.makeBoundHelper(receiptCredited);

  exports.receiptCredited = receiptCredited;
  exports['default'] = ReceiptCreditedHelper;
});