define('dispel-client/features/account/notifications/route', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin) {
  var AccountNotificationsRoute;

  AccountNotificationsRoute = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], {
    renderTemplate: function renderTemplate() {
      return this.render({
        into: 'account',
        outlet: 'notifications'
      });
    },
    model: function model() {
      return _ember['default'].RSVP.hash({
        user: this.store.peekRecord('user', this.get('currentUser.id'))
      });
    }
  });

  exports['default'] = AccountNotificationsRoute;
});