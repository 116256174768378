define('dispel-client/features/billing/receipts/route', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin) {
  var BillingReceiptsRoute;

  BillingReceiptsRoute = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], {
    titleToken: 'Receipts',
    renderTemplate: function renderTemplate() {
      return this.render({
        into: 'billing',
        outlet: 'receipts'
      });
    },
    model: function model() {
      return _ember['default'].RSVP.hash({
        receipts: this.store.query('receipt', {
          user_id: this.get('currentUser.id')
        }),
        receiptTypes: this.store.peekAll('receipt-type'),
        plans: this.store.peekAll('subscription')
      });
    }
  });

  exports['default'] = BillingReceiptsRoute;
});