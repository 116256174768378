define('dispel-client/models/waitlist', ['exports', 'ember-data'], function (exports, _emberData) {
  var Waitlist;

  Waitlist = _emberData['default'].Model.extend({
    created_at: _emberData['default'].attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    email: _emberData['default'].attr('string')
  });

  exports['default'] = Waitlist;
});