define('dispel-client/helpers/upper-case', ['exports', 'ember'], function (exports, _ember) {
  var UpperCaseHelper, upperCase;

  exports.upperCase = upperCase = function (params) {
    var _string, _uppercase;
    _string = params[0];
    _uppercase = _string.charAt(0).toUpperCase() + _string.slice(1);
    return _uppercase;
  };

  UpperCaseHelper = _ember['default'].Helper.helper(upperCase);

  exports.upperCase = upperCase;
  exports['default'] = UpperCaseHelper;
});