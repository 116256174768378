define('dispel-client/features/components/enable-notify/component', ['exports', 'ember'], function (exports, _ember) {
  var AccountEnableNotifyComponent;

  AccountEnableNotifyComponent = _ember['default'].Component.extend({
    notificationMessage: _ember['default'].Object.create({
      "class": void 0,
      message: ''
    }),
    receiptMessage: _ember['default'].Object.create({
      "class": void 0,
      message: ''
    }),
    actions: {
      turnOnDesktopNotifications: function turnOnDesktopNotifications() {
        var _notification, self;
        self = this;
        if (!window.Notification) {
          Materialize.toast('We\'re sorry, but your browser isn\'t compatible with desktop notifications.', 6000, 'red accent-2');
          return;
        }
        if (self.get('user.show_notifications') === false) {
          if (Notification.permission !== 'granted') {
            Materialize.toast('Almost there!', 6000, 'green accent-2 blue-grey-text text-darken-4');
            Notification.requestPermission((function (_this) {
              return function (permission) {
                var _notification;
                if (permission === 'granted') {
                  Materialize.toast('Fantastic! You\'re all set.', 6000, 'green accent-2 blue-grey-text text-darken-4');
                  _this.set('user.show_notifications', true);
                  _notification = new Notification('Notifications are turned on.', {
                    body: 'We can now let you know about events that need your attention so you can focus on other windows.',
                    dir: 'auto'
                  });
                  return setTimeout(function () {
                    _notification.close();
                  }, 5000);
                } else {
                  return Materialize.toast('Notifications were not enabled', 6000, 'red accent-2');
                }
              };
            })(this));
          } else {
            this.set('user.show_notifications', true);
            _notification = new Notification('Great! Notifications are turned on.', {
              body: 'We can now let you know about events that need your attention so you can focus on other windows.'
            });
            setTimeout(function () {
              _notification.close();
            }, 5000);
          }
        } else {
          Materialize.toast('Desktop notifications have been disabled.', 6000, 'red accent-2');
          this.set('user.show_notifications', false);
        }
        return _ember['default'].$.ajax({
          url: this.config.Variables.API_URL + '/api/users/' + this.get('currentUser.id'),
          method: 'PUT',
          data: {
            user: {
              show_notifications: this.get('user.show_notifications')
            }
          },
          header: "Bearer " + this.get('session.secure.token')
        }).fail((function (_this) {
          return function (err) {
            err = JSON.parse(err);
            return console.log('UPDATE SHOW NOTIFICATIONS ERROR', err);
          };
        })(this));
      }
    }
  });

  exports['default'] = AccountEnableNotifyComponent;
});