define("dispel-client/templates/atoms/progress-bar", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.10",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 0
          }
        },
        "moduleName": "dispel-client/templates/atoms/progress-bar.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "progress");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "progress-bar progress-bar-success progress-bar-striped active");
        dom.setAttribute(el2, "role", "progressbar");
        dom.setAttribute(el2, "aria-valuemin", "0");
        dom.setAttribute(el2, "aria-valuemax", "100");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element0, 'aria-valuenow');
        morphs[1] = dom.createAttrMorph(element0, 'style');
        return morphs;
      },
      statements: [["attribute", "aria-valuenow", ["get", "loaded", ["loc", [null, [2, 150], [2, 156]]]]], ["attribute", "style", ["get", "progressBarStyle", ["loc", [null, [2, 167], [2, 183]]]]]],
      locals: [],
      templates: []
    };
  })());
});