define('dispel-client/app', ['exports', 'ember', 'ember/resolver', 'ember/load-initializers', 'dispel-client/config/environment'], function (exports, _ember, _emberResolver, _emberLoadInitializers, _dispelClientConfigEnvironment) {
  var App;

  App = void 0;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _dispelClientConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _dispelClientConfigEnvironment['default'].podModulePrefix,
    Resolver: _emberResolver['default'],
    rootElement: '#ember-container'
  });

  _ember['default'].LinkComponent.reopen({
    attributeBindings: ['data-toggle', 'data-placement', 'title']
  });

  (0, _emberLoadInitializers['default'])(App, _dispelClientConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});