define('dispel-client/helpers/receipt-type', ['exports', 'ember'], function (exports, _ember) {
  var ReceiptTypeHelper, receiptType;

  exports.receiptType = receiptType = function (params) {
    var _receiptType, receiptTypes, receipt_type_id;
    receipt_type_id = params[0].toString();
    receiptTypes = params[1];
    _receiptType = receiptTypes.findBy('id', receipt_type_id).get('type');
    return _receiptType;
  };

  ReceiptTypeHelper = _ember['default'].Helper.helper(receiptType);

  exports.receiptType = receiptType;
  exports['default'] = ReceiptTypeHelper;
});