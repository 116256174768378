define('dispel-client/helpers/days-remaining-in-billing-cycle', ['exports', 'ember'], function (exports, _ember) {
  var DaysRemainingInBillingCycleHelper, daysRemainingInBillingCycle;

  exports.daysRemainingInBillingCycle = daysRemainingInBillingCycle = function (params) {
    var bill_28_days, bill_29_days, bill_30_days, bill_31_days, current_DOM, current_date, current_month, current_month_days, days_remaining, days_remaining_in_month, next_month, next_month_days;
    bill_31_days = params[0];
    bill_30_days = params[1];
    bill_29_days = params[2];
    bill_28_days = params[3];
    current_date = new Date();
    current_DOM = current_date.getUTCDate();
    current_month = new Date(current_date.getUTCFullYear(), current_date.getUTCMonth() + 1, 0);
    current_month_days = current_month.getUTCDate();
    next_month = new Date(current_date.getUTCFullYear(), current_date.getUTCMonth() + 2, 0);
    next_month_days = next_month.getUTCDate();
    days_remaining_in_month = current_month_days - current_DOM;
    if (current_month_days === 31) {
      if (current_DOM >= bill_31_days) {
        if (next_month_days === 31) {
          days_remaining = (bill_31_days + days_remaining_in_month).toString();
        } else if (next_month_days === 30) {
          days_remaining = (bill_30_days + days_remaining_in_month).toString();
        } else if (next_month_days === 29) {
          days_remaining = (bill_29_days + days_remaining_in_month).toString();
        } else if (next_month_days === 28) {
          days_remaining = (bill_28_days + days_remaining_in_month).toString();
        }
      } else if (current_DOM < bill_31_days) {
        days_remaining = (bill_31_days - current_DOM).toString();
      }
    } else if (current_month_days === 30) {
      if (current_DOM >= bill_30_days) {
        if (next_month_days === 31) {
          days_remaining = (bill_31_days + days_remaining_in_month).toString();
        } else if (next_month_days === 30) {
          days_remaining = (bill_30_days + days_remaining_in_month).toString();
        } else if (next_month_days === 29) {
          days_remaining = (bill_29_days + days_remaining_in_month).toString();
        } else if (next_month_days === 28) {
          days_remaining = (bill_28_days + days_remaining_in_month).toString();
        }
      } else if (current_DOM < bill_30_days) {
        days_remaining = (bill_30_days - current_DOM).toString();
      }
    } else if (current_month_days === 29) {
      if (current_DOM >= bill_29_days) {
        if (next_month_days === 31) {
          days_remaining = (bill_31_days + days_remaining_in_month).toString();
        } else if (next_month_days === 30) {
          days_remaining = (bill_30_days + days_remaining_in_month).toString();
        } else if (next_month_days === 29) {
          days_remaining = (bill_29_days + days_remaining_in_month).toString();
        } else if (next_month_days === 28) {
          days_remaining = (bill_28_days + days_remaining_in_month).toString();
        }
      } else if (current_DOM < bill_29_days) {
        days_remaining = (bill_29_days - current_DOM).toString();
      }
    } else if (current_month_days === 28) {
      if (current_DOM >= bill_28_days) {
        if (next_month_days === 31) {
          days_remaining = (bill_31_days + days_remaining_in_month).toString();
        } else if (next_month_days === 30) {
          days_remaining = (bill_30_days + days_remaining_in_month).toString();
        } else if (next_month_days === 29) {
          days_remaining = (bill_29_days + days_remaining_in_month).toString();
        } else if (next_month_days === 28) {
          days_remaining = (bill_28_days + days_remaining_in_month).toString();
        }
      } else if (current_DOM < bill_28_days) {
        days_remaining = (bill_28_days - current_DOM).toString();
      }
    }
    if (parseInt(days_remaining) > 1) {
      return 'you have (' + days_remaining + ') days remaining until your next monthly charge.';
    } else if (parseInt(days_remaining) === 1) {
      return 'you have (1) day remaining until your next bill, downgrades/cancelations must be completed before 5pm EST or else they will not take effect until after the next billing cycle';
    }
  };

  DaysRemainingInBillingCycleHelper = _ember['default'].HTMLBars.makeBoundHelper(daysRemainingInBillingCycle);

  exports.daysRemainingInBillingCycle = daysRemainingInBillingCycle;
  exports['default'] = DaysRemainingInBillingCycleHelper;
});