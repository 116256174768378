define('dispel-client/features/terms-of-service/controller', ['exports', 'ember'], function (exports, _ember) {
  var TermsOfServiceController;

  TermsOfServiceController = _ember['default'].Controller.extend({
    variables: (function () {
      return this.config.Variables;
    }).property()
  });

  exports['default'] = TermsOfServiceController;
});