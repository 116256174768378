define('dispel-client/features/service/route', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin) {
  var ServiceRoute;

  ServiceRoute = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], {
    titleToken: 'Mission Control',
    model: function model() {
      return _ember['default'].RSVP.hash({
        services: this.store.findAll('service'),
        user: this.store.findRecord('user', this.get('currentUser.id')),
        silentComputer: this.store.queryRecord('service', {
          type: 'desktop-retail'
        }),
        silentConnection: this.store.queryRecord('service', {
          type: 'vpn'
        }),
        subscription: this.store.findRecord('subscription', this.get('currentUser.subscription_id'))
      });
    },
    actions: {
      resetModel: function resetModel() {
        return this.refresh();
      }
    }
  });

  exports['default'] = ServiceRoute;
});