define('dispel-client/helpers/credit-reserve', ['exports', 'ember'], function (exports, _ember) {
  var CreditReserveHelper, creditReserve;

  exports.creditReserve = creditReserve = function (params) {
    var credit_reserve, return_string;
    credit_reserve = params[0];
    if (credit_reserve === 1) {
      return_string = _ember['default'].String.htmlSafe('you also have access to <strong>' + "1 overage credit" + '</strong>' + ", which provides you with");
    }
    if (credit_reserve > 1) {
      return_string = _ember['default'].String.htmlSafe('you also have access to <strong>' + credit_reserve.toString() + ' overage credits' + '</strong>' + ", which provide you with");
    }
    if (credit_reserve < 1) {
      return_string = 'you also have access to ';
    }
    return return_string;
  };

  CreditReserveHelper = _ember['default'].HTMLBars.makeBoundHelper(creditReserve);

  exports.creditReserve = creditReserve;
  exports['default'] = CreditReserveHelper;
});