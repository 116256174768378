define('dispel-client/models/receipt-type', ['exports', 'ember-data'], function (exports, _emberData) {
  var ReceiptType;

  ReceiptType = _emberData['default'].Model.extend({
    receipts: _emberData['default'].hasMany('receipts', {
      async: true
    }),
    type: _emberData['default'].attr('string')
  });

  exports['default'] = ReceiptType;
});