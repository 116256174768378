define('dispel-client/features/account/usage/route', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin) {
  var AccountUsageRoute;

  AccountUsageRoute = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], {
    renderTemplate: function renderTemplate() {
      return this.render({
        into: 'account',
        outlet: 'usage'
      });
    },
    model: function model() {
      return _ember['default'].RSVP.hash({
        projects: this.store.query('project', {
          user_id: this.get('currentUser.id')
        }),
        services: this.store.peekAll('service'),
        user: this.store.peekRecord('user', this.get('currentUser.id'))
      });
    }
  });

  exports['default'] = AccountUsageRoute;
});