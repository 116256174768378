define('dispel-client/models/payment', ['exports', 'ember-data'], function (exports, _emberData) {
  var Payment;

  Payment = _emberData['default'].Model.extend({
    user_id: _emberData['default'].attr('string'),
    payment_plan: _emberData['default'].attr('string'),
    credits_purchased: _emberData['default'].attr('string'),
    paypal_transaction_id: _emberData['default'].attr('string'),
    paypal_intent: _emberData['default'].attr('string'),
    payment_method: _emberData['default'].attr('string'),
    transaction_state: _emberData['default'].attr('string'),
    credits_before: _emberData['default'].attr('string'),
    credits_after: _emberData['default'].attr('string'),
    credits_paid: _emberData['default'].attr('string'),
    created: _emberData['default'].attr('date'),
    user: _emberData['default'].belongsTo('user', {
      async: true
    }),
    creditCard: _emberData['default'].belongsTo('creditCard', {
      async: true
    })
  });

  exports['default'] = Payment;
});