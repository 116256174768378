define('dispel-client/features/billing/controller', ['exports', 'ember'], function (exports, _ember) {
  var BillingController;

  BillingController = _ember['default'].Controller.extend({
    pageName: 'Billing',
    redeemingCoupon: false,
    canRedeemCoupon: false,
    $form: void 0,
    $coupon: void 0,
    coupon_code: void 0,
    couponBillBar: false,
    urlPrefix: (function () {
      return this.config.Variables.API_URL + '/' + this.config.Variables.URL_NAMESPACE;
    }).property(),
    setVariables: (function () {
      return this.parsley();
    }).on('didInsertElement'),
    canRedeemCouponWatcher: function canRedeemCouponWatcher() {
      var created_at, current_date, difference;
      created_at = this.get('model.user.created_at');
      current_date = new Date();
      difference = Math.floor((current_date - created_at) / (1000 * 24 * 60 * 60));
      if (difference > 3) {
        return this.toggleProperty('canRedeemCoupon');
      }
    },
    redeemingCouponWatcher: (function () {
      if (this.get('redeemingCoupon')) {
        return setTimeout((function (_this) {
          return function () {
            _this.set('$form', $('form'));
            _this.set('$coupon', _this.$form.find('.redeem--coupon'));
            _this.$coupon.parsley().on('field:success', function (event, formInstance) {
              return _this.set('validCoupon', true);
            });
            _this.$coupon.parsley().on('field:error', function (event, formInstance) {
              return _this.set('validCoupon', false);
            });
          };
        })(this), 100);
      }
    }).observes('redeemingCoupon'),
    setupCouponBillBar: (function () {
      var _can_build_desktop, _can_build_vpn;
      this.canRedeemCouponWatcher();
      _can_build_vpn = this.get('model.user.can_build_vpn');
      _can_build_desktop = this.get('model.user.can_build_desktop');
      if (_can_build_vpn === false || _can_build_desktop === false) {
        return this.set('couponBillBar', true);
      } else if (_can_build_desktop === true && _can_build_vpn === true) {
        return this.set('couponBillBar', false);
      }
    }).observes('model.user'),
    couponBillBarSetup: (function () {
      return this.setupCouponBillBar();
    }).on('didInsertElement'),
    actions: {
      toggleRedeemCoupon: function toggleRedeemCoupon() {
        return this.toggleProperty('redeemingCoupon');
      },
      redeemCoupon: function redeemCoupon() {
        var _onFail, _onSuccess, _user, couponData;
        _user = this.get('model.user');
        Materialize.toast("<i class='material-icons rotating light-blue-text '>autorenew</i> Submitting your coupon information", 1000);
        couponData = {
          coupon_code: _user.get('coupon_code')
        };
        _ember['default'].$.post(this.get('urlPrefix') + '/redeem-coupon', couponData).then(function (response, textStatus, xhr) {
          _onSuccess(response, textStatus, xhr);
        }, function (error) {
          _onFail();
        });
        _onSuccess = (function (_this) {
          return function (response, textStatus, xhr) {
            response = JSON.parse(response);
            _this.toggleProperty('redeemingCoupon');
            if (response.status === 200) {
              Materialize.toast("<i class='material-icons'>check_circle</i> Coupon Redeemed! Taking you to Mission Control.", 4000, 'green');
              return _this.transitionTo('service');
            } else if (response.status === 403) {
              return Materialize.toast("<i class='material-icons'>warning</i> this coupon has expired.", 4000, 'red');
            } else if (response.status === 409) {
              return Materialize.toast("<i class='material-icons'>warning</i> you have already redeemed this coupon.", 4000, 'red');
            } else if (response.status === 408) {
              return Materialize.toast("<i class='material-icons'>warning</i> you have already redeemed another coupon.", 4000, 'red');
            } else if (response.status === 407) {
              return Materialize.toast("<i class='material-icons'>warning</i> This coupon does not exist.", 4000, 'red');
            }
          };
        })(this);
        return _onFail = (function (_this) {
          return function (returnedObj) {
            Materialize.toast("Our servers failed to locate your coupon.<br>With deep self-loathing, we apologize and ask forgiveness.<br>Please  try again later, and let us know.", 4000, 'red');
            return Raven.captureException(new Error('Signup verification failed'), {
              extra: {
                returnedObj: returnedObj
              }
            });
          };
        })(this);
      },
      resolveBill: function resolveBill() {
        return swal({
          title: "Resolve Overage Charge",
          text: "Your account will be charged $10 for the overage credit. Don't worry. If you do not use the whole credit, you will receive a discount on next month's bill.",
          imageUrl: "/assets/images/credit_cards/bill.svg",
          showCancelButton: true,
          confirmButtonColor: '#08bcff',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Nevermind',
          closeOnConfirm: false,
          closeOnCancel: true,
          showLoaderOnConfirm: true,
          allowOutsideClick: true
        }, (function (_this) {
          return function (isConfirm) {
            var _onFail, _onSuccess;
            if (isConfirm) {
              swal({
                title: 'Processing your payment',
                text: "<p>Please wait a moment while we process your payment.</p>",
                imageUrl: "/assets/animations/console-loop.gif",
                html: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                closeOnConfirm: false,
                showConfirmButton: false
              });
              _ember['default'].$.post(_this.get('urlPrefix') + '/resolve-bill').then(function (response, textStatus, xhr) {
                _onSuccess(response, textStatus, xhr);
              }, function (error) {
                _onFail();
              });
              _onSuccess = function (response, textStatus, xhr) {
                _this.send('resetModel');
                response = JSON.parse(response);
                if (response.status === 200) {
                  swal("Success!", "Your ready to get back online", "success");
                  return _this.transitionTo('service');
                } else if (response.status === 403) {
                  return swal("Oops!", "You do not have an active credit card, please add a credit card and try again.", "error");
                } else if (response.status === 405) {
                  return swal("Oops!", "Your credit card has expired, use a different card and try again.", "error");
                } else if (response.status === 407) {
                  return swal({
                    title: "Oops!",
                    text: "Your payment failed to process, please <a href='mailto:support@dispel.io'>contact us</a> to resolve the issue.",
                    type: "error",
                    html: true
                  });
                }
              };
              return _onFail = function (returnedObj) {
                return swal({
                  title: "Oh no!",
                  text: "Our servers failed to resolve your bill. With deep self-loathing, we apologize and ask forgiveness. Please <a href='mailto:support@dispel.io'>contact us</a>.",
                  type: "error",
                  html: true
                });
              };
            }
          };
        })(this));
      }
    }
  });

  exports['default'] = BillingController;
});