define('dispel-client/models/receipt', ['exports', 'ember-data'], function (exports, _emberData) {
  var Receipt;

  Receipt = _emberData['default'].Model.extend({
    user: _emberData['default'].belongsTo('user', {
      async: true
    }),
    user_id: _emberData['default'].attr('number'),
    amount: _emberData['default'].attr('number'),
    created_at: _emberData['default'].attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    payment_received: _emberData['default'].attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    pending: _emberData['default'].attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    censored_credit_card_number: _emberData['default'].attr('string'),
    invoice_id: _emberData['default'].attr('string'),
    receipt_type: _emberData['default'].belongsTo('receipt_type', {
      async: true
    }),
    receipt_type_id: _emberData['default'].attr('number'),
    old_subscription: _emberData['default'].attr('number'),
    new_subscription: _emberData['default'].attr('number'),
    credits_purchased: _emberData['default'].attr('number'),
    end_of_month_credits: _emberData['default'].attr('number'),
    bandwidth_used: _emberData['default'].attr('number'),
    computing_minutes_used: _emberData['default'].attr('number')
  });

  exports['default'] = Receipt;
});