define('dispel-client/features/signup/controller', ['exports', 'ember'], function (exports, _ember) {
  var SignupController;

  SignupController = _ember['default'].Controller.extend({
    credentialsTabAvailable: true,
    planTabAvailable: 'disabled',
    paymentTabAvailable: 'disabled',
    doneTabAvailable: 'disabled',
    credentialsTabComplete: false,
    planTabComplete: false,
    paymentTabComplete: false,
    doneTabComplete: false,
    trialPlanSelected: false,
    subscriptionName: '',
    subscriptionPrice: 0,
    queryParams: ['verification'],
    verification: null,
    openTerms: false,
    openPrivacy: false,
    openEndUser: false
  });

  exports['default'] = SignupController;
});