define('dispel-client/features/signup/payment/route', ['exports', 'ember', 'simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _ember, _simpleAuthMixinsUnauthenticatedRouteMixin) {
  var SignupPaymentRoute;

  SignupPaymentRoute = _ember['default'].Route.extend(_simpleAuthMixinsUnauthenticatedRouteMixin['default'], {
    beforeModel: function beforeModel(transition) {
      var credentialsIncomplete, planTabIncomplete;
      this._super(transition);
      credentialsIncomplete = !this.controllerFor('signup').get('credentialsTabComplete');
      planTabIncomplete = !this.controllerFor('signup').get('planTabComplete');
      if (credentialsIncomplete || planTabIncomplete) {
        return this.transitionTo('signup.credentials');
      }
    },
    afterModel: function afterModel(model, transition) {
      return transition.send('editPayment');
    }
  });

  exports['default'] = SignupPaymentRoute;
});