define('dispel-client/features/login/controller', ['exports', 'ember'], function (exports, _ember) {
  var LoginController;

  LoginController = _ember['default'].Controller.extend({
    signingUp: false,
    termsOfUse: void 0,
    variables: (function () {
      var vars;
      vars = this.config.Variables;
      return vars;
    }).property(),
    actions: {
      showLegal: function showLegal(type) {
        if (type === 'tos') {
          return this.set('termsOfUse', true);
        } else {
          return this.set('termsOfUse', false);
        }
      }
    }
  });

  exports['default'] = LoginController;
});