define('dispel-client/features/signup/credentials/route', ['exports', 'ember', 'simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _ember, _simpleAuthMixinsUnauthenticatedRouteMixin) {
  var SignupCredentialsRoute;

  SignupCredentialsRoute = _ember['default'].Route.extend(_simpleAuthMixinsUnauthenticatedRouteMixin['default'], {
    afterModel: function afterModel(model, transition) {
      return transition.send('editEmail');
    }
  });

  exports['default'] = SignupCredentialsRoute;
});