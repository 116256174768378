define('dispel-client/adapters/application', ['exports', 'ember-data', 'ember', 'dispel-client/config/environment'], function (exports, _emberData, _ember, _dispelClientConfigEnvironment) {
  var ApplicationAdapter;

  ApplicationAdapter = _emberData['default'].RESTAdapter.extend({
    host: _dispelClientConfigEnvironment['default'].Variables.API_URL,
    namespace: _dispelClientConfigEnvironment['default'].Variables.URL_NAMESPACE,
    shouldReloadAll: function shouldReloadAll(store, snapshotRecordArray) {
      return true;
    },
    shouldBackgroundReloadAll: function shouldBackgroundReloadAll(store, snapshotRecordArray) {
      return true;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord(store, snapshotRecordArray) {
      return true;
    },
    ajax: function ajax(url, method, hash) {
      if (hash && hash.data && hash.data.noAuth) {
        url = _dispelClientConfigEnvironment['default'].Variables.API_URL + '/subscriptions';
        hash = {};
      }
      hash = hash || {};
      hash.crossDomain = true;
      return this._super(url, method, hash);
    }
  });

  exports['default'] = ApplicationAdapter;
});