define('dispel-client/validators/local/password', ['exports', 'ember', 'ember-validations/validators/base'], function (exports, _ember, _emberValidationsValidatorsBase) {
  var emailValidation;

  emailValidation = _emberValidationsValidatorsBase['default'].extend({
    call: function call() {
      var property;
      property = this.model.get(this.property);
      if (!_ember['default'].isEmpty(property)) {
        if (/\s/g.test(property)) {
          return this.errors.pushObject('Your password must cannot contain whitespace.');
        }
        if (!/[A-Z]{1,}/g.test(property)) {
          return this.errors.pushObject('Your password must have at least one (1) uppercase letter.');
        }
        if (!/[a-z]{1,}/g.test(property)) {
          return this.errors.pushObject('Your password must have at least one (1) lowercase letter.');
        }
        if (!/[0-9]{1,}/g.test(property)) {
          return this.errors.pushObject('Your password must have at least one (1) number.');
        }
        if (!/[!@#\$%\^\&*\)\(]{1,}/g.test(property)) {
          return this.errors.pushObject('Your password must have at least one (1) special character: ! @ # $ % ^ & * ) (');
        }
        if (property.length < 8) {
          return this.errors.pushObject('Your password must be eight (8) characters long.');
        }
      }
    }
  });

  exports['default'] = emailValidation;
});