define('dispel-client/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  var User;

  User = _emberData['default'].Model.extend({
    email: _emberData['default'].attr('string'),
    phone: _emberData['default'].attr('string'),
    times_visited: _emberData['default'].attr('number'),
    hours_visited: _emberData['default'].attr('string'),
    credits: _emberData['default'].attr('number'),
    created_at: _emberData['default'].attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    updated_at: _emberData['default'].attr('date'),
    make_receipts: _emberData['default'].attr('boolean'),
    show_notifications: _emberData['default'].attr('boolean'),
    accepted_terms_of_service: _emberData['default'].attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    accepted_privacy_policy: _emberData['default'].attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    validated_email: _emberData['default'].attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    subscription_id: _emberData['default'].attr('number'),
    bandwidth_used: _emberData['default'].attr('number', {
      defaultValue: function defaultValue() {
        return 0;
      }
    }),
    computing_minutes_used: _emberData['default'].attr('number', {
      defaultValue: function defaultValue() {
        return 0;
      }
    }),
    subscription_signup_date: _emberData['default'].attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    downgrade_requested: _emberData['default'].attr('number'),
    overage_autocharge_permission: _emberData['default'].attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    credit_reserve: _emberData['default'].attr('number', {
      defaultValue: function defaultValue() {
        return 0;
      }
    }),
    can_build_desktop: _emberData['default'].attr('boolean', {
      defaultValue: function defaultValue() {
        return true;
      }
    }),
    can_build_vpn: _emberData['default'].attr('boolean', {
      defaultValue: function defaultValue() {
        return true;
      }
    }),
    free_trial_expired: _emberData['default'].attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    bill_31_days: _emberData['default'].attr('number'),
    bill_30_days: _emberData['default'].attr('number'),
    bill_29_days: _emberData['default'].attr('number'),
    bill_28_days: _emberData['default'].attr('number'),
    referred_from: _emberData['default'].attr('string'),
    referral_code: _emberData['default'].attr('string'),
    projects: _emberData['default'].hasMany('project', {
      async: true
    }),
    feedbacks: _emberData['default'].hasMany('feedback', {
      async: true
    }),
    payments: _emberData['default'].hasMany('payment', {
      async: true
    }),
    creditCards: _emberData['default'].hasMany('creditCards', {
      async: true
    }),
    receipts: _emberData['default'].hasMany('receipts', {
      async: true
    }),
    subscription: _emberData['default'].belongsTo('subscription', {
      async: true
    })
  });

  exports['default'] = User;
});