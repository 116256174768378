define('dispel-client/features/billing/cards/controller', ['exports', 'ember'], function (exports, _ember) {
  var BillingCardsController;

  BillingCardsController = _ember['default'].Controller.extend({
    urlPrefix: (function () {
      return this.config.Variables.API_URL + '/' + this.config.Variables.URL_NAMESPACE;
    }).property(),
    sortedCards: (function () {
      return _ember['default'].ArrayProxy.extend(_ember['default'].SortableMixin).create({
        sortProperties: ['primary_card'],
        sortAscending: false,
        content: this.get('model.cards')
      });
    }).property('model.cards'),
    actions: {
      activateCard: function activateCard(card) {
        var _old_card;
        if (this.get('model.cards').findBy('primary_card', true)) {
          _old_card = this.get('model.cards').findBy('primary_card', true);
          _old_card.set('primary_card', false);
          if (_old_card.get('isDirty')) {
            _old_card.save();
          }
        }
        card.set('primary_card', true);
        if (card.get('isDirty')) {
          return card.save().then((function (_this) {
            return function () {
              return swal("Success!", "You have updated your credit card preferences", "success");
            };
          })(this));
        }
      },
      deleteCard: function deleteCard(card) {
        var cardData;
        Materialize.toast("Deleting credit card...", 3500);
        cardData = {
          id: card.get('id'),
          payer_id: card.get('payer_id'),
          credit_card_id: card.get('credit_card_id')
        };
        return _ember['default'].$.post(this.get('urlPrefix') + '/delete-credit-card', {
          cardData: cardData
        }).fail((function (_this) {
          return function (err) {
            err = JSON.parse(err);
            console.log('SAVED CARD FAIL', err);
            return Materialize.toast("Sorry, we could not delete your card. Our developers have been notified.", 6000, 'red accent-2');
          };
        })(this)).done((function (_this) {
          return function (res) {
            res = JSON.parse(res);
            _this.send('resetModel');
            return Materialize.toast("Your card was successfully deleted! <i class='material-icons green-text'>check_circle</i>", 3500);
          };
        })(this));
      },
      billingCardsHelp: function billingCardsHelp() {
        return swal({
          title: 'Credit Cards',
          text: "<p>You’ll need an active credit card to maintain your Dispel account. You can add additional credit cards at anytime and delete old ones if they expire or you decide to remove them. If you have more than one card, you’ll have to select “Activate Card” to set one of those cards as your “Active” primary card. Whenever you add a new card, that card will automatically activate. Dispel trusts <em>PayPal</em> to keep your credit card information safe and we do not keep any sensitive information. If you need further assistance, open the chat window at the bottom-right corner of the page and send us a quick message.</p>",
          imageUrl: "/assets/images/bulb.png",
          html: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
          closeOnConfirm: true,
          showConfirmButton: true
        });
      }
    }
  });

  exports['default'] = BillingCardsController;
});