define('dispel-client/helpers/lower-case', ['exports', 'ember'], function (exports, _ember) {
  var LowerCaseHelper, lowerCase;

  exports.lowerCase = lowerCase = function (params) {
    var _lowercase;
    if (!params) {
      return '';
    }
    _lowercase = params[0].toLowerCase();
    return _lowercase;
  };

  LowerCaseHelper = _ember['default'].Helper.helper(lowerCase);

  exports.lowerCase = lowerCase;
  exports['default'] = LowerCaseHelper;
});