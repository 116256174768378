define('dispel-client/features/components/navigation-sidebar/component', ['exports', 'ember'], function (exports, _ember) {
  var NavigationSidebarComponent;

  NavigationSidebarComponent = _ember['default'].Component.extend({
    variables: (function () {
      return this.config.Variables;
    }).property(),
    isAdmin: false,
    didInsertElement: function didInsertElement() {
      return this.notifyPropertyChange('identicon');
    },
    identicon: (function () {
      var _data, _email;
      _email = this.get('currentUser.email');
      if (_email) {
        _data = new Identicon(md5(_email), 30).toString();
        return _data;
      }
    }).property('currentUser.email'),
    checkAdmin: (function () {
      var admins, isAdmin, userEmail;
      userEmail = this.get('controllers.login.email') || localStorage.email;
      admins = ['jdillon@bvhllc.com', 'ethan@dispel.io'];
      isAdmin = admins.filter(function (email) {
        return email === userEmail;
      });
      return this.set('isAdmin', !!isAdmin.length);
    }).on('init').observes('controllers.login.email'),
    setCreditAlert: (function () {
      var _creditAlertThreshold, _notification;
      _creditAlertThreshold = 10;
      if (this.get('user.credits') < _creditAlertThreshold) {
        if (this.get('user.show_notifications')) {
          _notification = new Notification(this.variables.PROJECT_CAPITAL + ' credits are low.', {
            body: 'You\'ve got less than ' + _creditAlertThreshold.toString() + ' credits remaining in your ' + this.variables.PROJECT_CAPITAL + ' account. Your service will shut down soon if you don\'t add more.',
            icon: '/assets/images/' + this.variables.LOGO,
            dir: 'auto'
          });
          setTimeout(function () {
            _notification.close();
          }, 2500);
        }
        return this.set('creditAlert', true);
      } else {
        return this.set('creditAlert', false);
      }
    }).observes('user.credits'),
    actions: {
      invalidateSession: function invalidateSession() {
        return this.get('session').invalidate();
      }
    }
  });

  exports['default'] = NavigationSidebarComponent;
});