define('dispel-client/features/billing/route', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin) {
  var BillingRoute;

  BillingRoute = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], {
    titleToken: 'Billing',
    redirect: function redirect(model, transition) {
      if (transition.targetName === 'billing.index') {
        return this.transitionTo('billing.overview');
      }
    },
    model: function model() {
      var self;
      self = this;
      return _ember['default'].RSVP.hash({
        services: this.store.peekAll('service'),
        user: this.store.findRecord('user', this.get('currentUser.id')),
        plans: this.store.findAll('subscription'),
        receiptTypes: this.store.findAll('receipt-type')
      }).then(function (success) {
        success.subscription = self.store.peekRecord('subscription', self.get('currentUser.subscription_id'));
        return success;
      }, function (fail) {
        return console.log('BILLING ROUTE FAILURE (make this mo better)', fail);
      });
    },
    actions: {
      resetModel: function resetModel() {
        return this.refresh();
      }
    }
  });

  exports['default'] = BillingRoute;
});