define("dispel-client/templates/atoms/protect-and-comply", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.10",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 250
          }
        },
        "moduleName": "dispel-client/templates/atoms/protect-and-comply.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "page-header");
        dom.setAttribute(el1, "id", "protect_and_comply");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        var el3 = dom.createTextNode("Protect & Comply");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sit, accusamus tenetur? Atque neque ipsum libero aperiam ducimus autem qui, at accusamus ipsam hic iste, culpa vitae, dicta laborum molestiae nostrum?");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("Nulla nisi quam facere. Quod iste sit aspernatur harum beatae, repellendus obcaecati dolores ducimus optio cumque similique neque, animi dolorem rem tempore eum ab adipisci soluta iusto! Nobis, molestias, asperiores.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("Quo voluptates cumque, consectetur, cupiditate dolore, soluta nesciunt facilis rerum unde laudantium dolorem iure incidunt quidem animi. Atque ipsam assumenda, libero minus quisquam, nemo necessitatibus laudantium, perferendis sequi ipsa fuga!");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  })());
});