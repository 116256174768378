define('dispel-client/features/components/update-email/component', ['exports', 'ember'], function (exports, _ember) {
  var UpdateEmailComponent;

  UpdateEmailComponent = _ember['default'].Component.extend({
    $form: void 0,
    $email: void 0,
    $password: void 0,
    validEmail: false,
    validPassword: true,
    buttonStatus: 'btn disabled',
    originalEmail: void 0,
    setVariables: (function () {
      this.set('$form', $('form.update-email'));
      this.set('$email', this.$form.find('input[type=email]'));
      this.set('$password', this.$form.find('input[type=password]'));
      return this.parsley();
    }).on('didInsertElement'),
    setOriginalEmail: (function () {
      return this.set('originalEmail', this.get('currentUser.email'));
    }).on('init'),
    submittable: (function () {
      if (this.validEmail && this.validPassword) {
        this.set('buttonStatus', 'btn-robinBlue');
        return true;
      } else {
        this.set('buttonStatus', 'btn disabled');
        return false;
      }
    }).observes('validPassword', 'validEmail'),
    parsley: function parsley() {
      this.setButtonFunction();
      return this.$form.parsley();
    },
    setButtonFunction: function setButtonFunction() {
      this.$email.parsley().on('field:success', (function (_this) {
        return function (event, formInstance) {
          return _this.set('validEmail', true);
        };
      })(this));
      return this.$email.parsley().on('field:error', (function (_this) {
        return function (event, formInstance) {
          return _this.set('validEmail', false);
        };
      })(this));
    },
    actions: {
      updateEmail: function updateEmail() {
        var _onFail, _onSuccess, _updateEmailRequest, _updatedEmail, user;
        if (this.validEmail && this.validPassword) {
          if (this.$email.val() === this.get('originalEmail')) {
            Materialize.toast("That's the same email address.", 3500);
            return false;
          } else {
            _updatedEmail = {
              user: {
                email: this.$email.val(),
                password: this.$password.val()
              }
            };
            user = this.get('user');
            this.set('user.email', this.$email.val());
            if (user.get('isDirty')) {
              Materialize.toast("Updating your email.", 3500);
              _updateEmailRequest = _ember['default'].$.ajax({
                url: this.config.Variables.API_URL + '/api/users/' + user.id,
                method: 'put',
                data: _updatedEmail,
                header: "Bearer " + this.get('session.secure.token')
              });
            }
            _updateEmailRequest.done((function (_this) {
              return function (_response) {
                if (_response.error) {
                  return _onFail(_response);
                } else {
                  return _onSuccess(_response);
                }
              };
            })(this));
            _onSuccess = (function (_this) {
              return function (response) {
                var _successMessage;
                _successMessage = "Successfully updated your email to &nbsp;<strong> " + _this.$email.val() + "</strong>";
                return Materialize.toast(_successMessage, 3500, 'green accent-2 blue-grey-text text-darken-4');
              };
            })(this);
            _onFail = (function (_this) {
              return function (returnedObj) {
                var error;
                error = {
                  message: "That account password is incorrect."
                };
                Materialize.toast(error.message, 4000, 'red accent-2');
                return _this.$password.focus();
              };
            })(this);
          }
          return false;
        } else {
          Materialize.toast("Please enter your current password and a proper email", 3500);
          return false;
        }
      }
    }
  });

  exports['default'] = UpdateEmailComponent;
});