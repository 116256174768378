define('dispel-client/models/service', ['exports', 'ember-data'], function (exports, _emberData) {
  var Service;

  Service = _emberData['default'].Model.extend({
    type: _emberData['default'].attr('string'),
    projects: _emberData['default'].hasMany('project', {
      async: true
    }),
    friendly_name: _emberData['default'].attr('string'),
    short_name: _emberData['default'].attr('string'),
    port: _emberData['default'].attr('string')
  });

  exports['default'] = Service;
});