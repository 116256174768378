define('dispel-client/helpers/credit-desktop', ['exports', 'ember'], function (exports, _ember) {
  var CreditDesktopHelper, creditDesktop;

  exports.creditDesktop = creditDesktop = function (params) {
    var additional_desktop, credit_reserve;
    credit_reserve = params[0];
    additional_desktop = credit_reserve * 2;
    additional_desktop = additional_desktop.toString();
    return additional_desktop;
  };

  CreditDesktopHelper = _ember['default'].HTMLBars.makeBoundHelper(creditDesktop);

  exports.creditDesktop = creditDesktop;
  exports['default'] = CreditDesktopHelper;
});