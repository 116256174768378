define('dispel-client/features/signup/plans/route', ['exports', 'ember', 'simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _ember, _simpleAuthMixinsUnauthenticatedRouteMixin) {
  var SignupPlansRoute;

  SignupPlansRoute = _ember['default'].Route.extend(_simpleAuthMixinsUnauthenticatedRouteMixin['default'], {
    beforeModel: function beforeModel(transition) {
      this._super(transition);
      if (!this.controllerFor('signup.credentials').get('credentialsTabComplete')) {
        return this.transitionTo('signup.credentials');
      }
    },
    afterModel: function afterModel(model, transition) {
      return transition.send('editPlan');
    }
  });

  exports['default'] = SignupPlansRoute;
});