define('dispel-client/features/components/material-collapsible/component', ['exports', 'ember'], function (exports, _ember) {
  var MaterialCollapsibleComponent;

  MaterialCollapsibleComponent = _ember['default'].Component.extend({
    didInsertElement: function didInsertElement() {
      return $('.collapsible').collapsible();
    }
  });

  exports['default'] = MaterialCollapsibleComponent;
});