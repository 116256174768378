define('dispel-client/features/components/material-tabs/component', ['exports', 'ember'], function (exports, _ember) {
  var MaterialTabsComponent;

  MaterialTabsComponent = _ember['default'].Component.extend({
    didInsertElement: function didInsertElement() {
      return $('ul.tabs').tabs();
    }
  });

  exports['default'] = MaterialTabsComponent;
});