define('dispel-client/features/forgot-password/route', ['exports', 'ember', 'simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _ember, _simpleAuthMixinsUnauthenticatedRouteMixin) {
  var ForgotPasswordRoute;

  ForgotPasswordRoute = _ember['default'].Route.extend(_simpleAuthMixinsUnauthenticatedRouteMixin['default'], {
    titleToken: 'Forgot Password',
    redirect: function redirect(model, transition) {
      if (transition.queryParams.verification) {
        this.controllerFor('forgot-password').set('verification', transition.queryParams.verification);
      }
    }
  });

  exports['default'] = ForgotPasswordRoute;
});