define('dispel-client/features/components/usage-history/component', ['exports', 'ember'], function (exports, _ember) {
  var UsageHistoryComponent;

  UsageHistoryComponent = _ember['default'].Component.extend({
    formattedProjects: (function () {
      return _ember['default'].ArrayProxy.extend(_ember['default'].SortableMixin).create({
        sortProperties: ['millseconds'],
        sortAscending: true,
        content: this.get('projects').map((function (_this) {
          return function (value) {
            var data, hoursAgoStarted, hoursAgoStopped, id, millseconds, serviceType, sortId, started, stopped, timeDuration;
            sortId = value.get('millseconds');
            id = value.get('service_id');
            started = value.get('started');
            stopped = value.get('stopped');
            millseconds = moment().diff(moment(started));
            hoursAgoStarted = _this.hoursAgo(started);
            hoursAgoStopped = _this.hoursAgo(stopped);
            timeDuration = _this.timeDuration(started, stopped);
            serviceType = _this.serviceType(id, _this.get('services'));
            data = {
              hoursAgoStarted: hoursAgoStarted,
              hoursAgoStopped: hoursAgoStopped,
              timeDuration: timeDuration,
              serviceType: serviceType,
              millseconds: millseconds
            };
            if (data.timeDuration) {
              return data;
            }
          };
        })(this))
      });
    }).property(),
    hoursAgo: function hoursAgo(value) {
      var hours;
      if (!value) {
        return '-----';
      }
      hours = moment(value).fromNow();
      return hours;
    },
    timeDuration: function timeDuration(starting_datetime, ending_datetime) {
      var date, duration, floor, millseconds;
      if (!ending_datetime) {
        return 'Still active';
      }
      millseconds = moment(ending_datetime).diff(moment(starting_datetime));
      duration = moment.duration(millseconds);
      floor = Math.floor(duration.asHours());
      date = floor + moment.utc(millseconds).format(":mm");
      return date;
    },
    serviceType: function serviceType(id, services) {
      var name, serviceId;
      serviceId = id.toString();
      services = services.findBy('id', serviceId);
      name = services.get('friendly_name');
      return name;
    }
  });

  exports['default'] = UsageHistoryComponent;
});