define('dispel-client/features/billing/receipt/route', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin) {
  var BillingReceiptRoute;

  BillingReceiptRoute = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], {
    titleToken: 'Receipt',
    renderTemplate: function renderTemplate() {
      return this.render({
        into: 'billing',
        outlet: 'receipt'
      });
    },
    model: function model(params) {
      var self;
      self = this;
      return _ember['default'].RSVP.hash({
        individualReceipt: this.store.findRecord('receipt', params.id),
        receiptTypes: this.store.peekAll('receipt-type'),
        plans: this.store.peekAll('subscription'),
        user: this.store.peekRecord('user', this.get('currentUser.id'))
      });
    }
  });

  exports['default'] = BillingReceiptRoute;
});