define("dispel-client/features/components/login-form/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 45,
                "column": 6
              },
              "end": {
                "line": 58,
                "column": 6
              }
            },
            "moduleName": "dispel-client/features/components/login-form/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "id", "login--login-manager--confirm-password");
            dom.setAttribute(el1, "class", "password input-field col");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2, "for", "login--login-manager--confirm-password");
            var el3 = dom.createTextNode("Confirm your password");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "confirmPassword", ["loc", [null, [48, 18], [48, 33]]]]], [], []], "type", "password", "required", true, "aria-describedby", "password", "autocapitalize", "off", "autocorrect", "off", "class", ["subexpr", "concat", ["login--login-manager--login-input", " "], [], []]], ["loc", [null, [47, 10], [55, 12]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 59,
                "column": 6
              },
              "end": {
                "line": 73,
                "column": 6
              }
            },
            "moduleName": "dispel-client/features/components/login-form/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createTextNode("Passwords require one number, one uppercase, one lowercase, and one special character. Must be at least eight characters long.");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("small");
            var el3 = dom.createTextNode("By registering you are agreeing to our\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3, "href", "javascript#");
            var el4 = dom.createTextNode("Terms of Use");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("small");
            var el3 = dom.createTextNode("\n             and our ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3, "href", "javascript#");
            var el4 = dom.createTextNode("Privacy Policy");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [3, 1, 1]);
            var element8 = dom.childAt(fragment, [5, 1, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element7);
            morphs[1] = dom.createElementMorph(element8);
            return morphs;
          },
          statements: [["element", "action", ["showLegal", "tos"], [], ["loc", [null, [65, 34], [65, 62]]]], ["element", "action", ["showLegal", "pp"], [], ["loc", [null, [70, 43], [70, 70]]]]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 73,
                "column": 6
              },
              "end": {
                "line": 75,
                "column": 6
              }
            },
            "moduleName": "dispel-client/features/components/login-form/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "login--login-manager--submit waves-effect waves-light");
            var el2 = dom.createTextNode("Sign In");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element6);
            return morphs;
          },
          statements: [["element", "action", ["authenticate"], [], ["loc", [null, [74, 78], [74, 103]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 4
            },
            "end": {
              "line": 76,
              "column": 4
            }
          },
          "moduleName": "dispel-client/features/components/login-form/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "login--login-manager--username");
          dom.setAttribute(el1, "class", "username input-field col");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "for", "login--login-manager--username");
          dom.setAttribute(el2, "data-error", "That doesn't look like an email address");
          dom.setAttribute(el2, "data-success", "Looking good!");
          var el3 = dom.createTextNode("\n            Your email\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "login--login-manager--password");
          dom.setAttribute(el1, "class", "password input-field col");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "for", "login--login-manager--password");
          var el3 = dom.createTextNode("Your password");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1]);
          var element10 = dom.childAt(element9, [3]);
          var element11 = dom.childAt(fragment, [3]);
          var element12 = dom.childAt(element11, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(element9, 1, 1);
          morphs[1] = dom.createAttrMorph(element10, 'class');
          morphs[2] = dom.createMorphAt(element11, 1, 1);
          morphs[3] = dom.createAttrMorph(element12, 'class');
          morphs[4] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          morphs[5] = dom.createMorphAt(fragment, 6, 6, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "identification", ["loc", [null, [14, 16], [14, 30]]]]], [], []], "aria-describedby", "username", "type", "email", "required", true, "autofocus", "autofocus", "autocapitalize", "off", "autocorrect", "off", "class", ["subexpr", "concat", ["login--login-manager--login-input", " ", "validate", " "], [], []]], ["loc", [null, [13, 8], [22, 10]]]], ["attribute", "class", ["subexpr", "if", [["get", "identification", ["loc", [null, [24, 21], [24, 35]]]], "active"], [], ["loc", [null, [24, 16], [24, 46]]]]], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "password", ["loc", [null, [34, 16], [34, 24]]]]], [], []], "type", "password", "required", true, "aria-describedby", "password", "autocapitalize", "off", "autocorrect", "off", "class", ["subexpr", "concat", ["login--login-manager--login-input", " ", "validate", " "], [], []]], ["loc", [null, [33, 8], [41, 10]]]], ["attribute", "class", ["subexpr", "if", [["get", "password", ["loc", [null, [42, 26], [42, 34]]]], "active"], [], ["loc", [null, [42, 21], [42, 45]]]]], ["block", "liquid-if", [["get", "signingUp", ["loc", [null, [45, 19], [45, 28]]]]], ["class", "login-signup"], 0, null, ["loc", [null, [45, 6], [58, 20]]]], ["block", "liquid-if", [["get", "signingUp", ["loc", [null, [59, 19], [59, 28]]]]], ["class", "login-signup"], 1, 2, ["loc", [null, [59, 6], [75, 20]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 77,
              "column": 4
            },
            "end": {
              "line": 99,
              "column": 4
            }
          },
          "moduleName": "dispel-client/features/components/login-form/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "login--login-manager--username");
          dom.setAttribute(el1, "class", "username input-field col");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "for", "login--login-manager--username");
          dom.setAttribute(el2, "data-error", "That doesn't look like an email address");
          dom.setAttribute(el2, "data-success", "Looking good!");
          var el3 = dom.createTextNode("\n            Your email\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "login--login-manager--submit waves-effect waves-light");
          var el3 = dom.createTextNode("Reset Password");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(element3, [3]);
          var element5 = dom.childAt(element3, [7]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element3, 1, 1);
          morphs[1] = dom.createAttrMorph(element4, 'class');
          morphs[2] = dom.createElementMorph(element5);
          return morphs;
        },
        statements: [["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "identification", ["loc", [null, [80, 16], [80, 30]]]]], [], []], "aria-describedby", "username", "type", "email", "required", true, "autofocus", "autofocus", "autocapitalize", "off", "autocorrect", "off", "class", ["subexpr", "concat", ["login--login-manager--login-input", " ", "validate", " "], [], []]], ["loc", [null, [79, 8], [88, 10]]]], ["attribute", "class", ["subexpr", "if", [["get", "identification", ["loc", [null, [90, 21], [90, 35]]]], "active"], [], ["loc", [null, [90, 16], [90, 46]]]]], ["element", "action", ["forgotPassword"], [], ["loc", [null, [97, 78], [97, 105]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 104,
                "column": 6
              },
              "end": {
                "line": 106,
                "column": 6
              }
            },
            "moduleName": "dispel-client/features/components/login-form/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "}}", "");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createMorphAt(element2, 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["login--login-footer--message ", ["subexpr", "if", [["get", "notificationMessage.message", ["loc", [null, [105, 55], [105, 82]]]], "visible"], [], ["loc", [null, [105, 50], [105, 94]]]], " ", ["subexpr", "if", [["get", "notificationMessage.class", ["loc", [null, [105, 100], [105, 125]]]], ["get", "notificationMessage.class", ["loc", [null, [105, 126], [105, 151]]]], "lightblue"], [], ["loc", [null, [105, 95], [105, 165]]]]]]], ["content", "notificationMessage.message", ["loc", [null, [105, 169], [105, 200]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 106,
                "column": 6
              },
              "end": {
                "line": 108,
                "column": 6
              }
            },
            "moduleName": "dispel-client/features/components/login-form/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "class", "login--login-manager--signup");
            dom.setAttribute(el1, "href", "#");
            var el2 = dom.createTextNode("Forgot your password?");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [["element", "action", ["toggleForgotPassword"], [], ["loc", [null, [107, 57], [107, 90]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 103,
              "column": 4
            },
            "end": {
              "line": 109,
              "column": 4
            }
          },
          "moduleName": "dispel-client/features/components/login-form/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "notificationMessage.message", ["loc", [null, [104, 12], [104, 39]]]]], [], 0, 1, ["loc", [null, [104, 6], [108, 13]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 110,
              "column": 4
            },
            "end": {
              "line": 112,
              "column": 4
            }
          },
          "moduleName": "dispel-client/features/components/login-form/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "login--login-manager--signup");
          dom.setAttribute(el1, "href", "#");
          var el2 = dom.createTextNode("Return to Sign In");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [2]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [["element", "action", ["toggleForgotPassword"], [], ["loc", [null, [111, 59], [111, 92]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.10",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 116,
            "column": 0
          }
        },
        "moduleName": "dispel-client/features/components/login-form/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "login--login-manager--wrapper");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "login--login-manager--logo");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("img");
        dom.setAttribute(el3, "class", "logo visible");
        dom.setAttribute(el3, "src", "./assets/images/owl.svg");
        dom.setAttribute(el3, "alt", "Dispel");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "img signing-in-intro img-intro");
        dom.setAttribute(el3, "alt", "Signing in");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "img signing-in-loop img-loop");
        dom.setAttribute(el3, "alt", "Signing in");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "img error-intro img-loop");
        dom.setAttribute(el3, "alt", "Error");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("form");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "login--login-manger--footer");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode(" ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" .login--login-manager--wrapper ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element13 = dom.childAt(fragment, [0]);
        var element14 = dom.childAt(element13, [3]);
        var element15 = dom.childAt(element13, [5]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(element14, 1, 1);
        morphs[1] = dom.createMorphAt(element14, 2, 2);
        morphs[2] = dom.createMorphAt(element15, 1, 1);
        morphs[3] = dom.createMorphAt(element15, 2, 2);
        return morphs;
      },
      statements: [["block", "liquid-unless", [["get", "forgottenPassword", ["loc", [null, [11, 21], [11, 38]]]]], [], 0, null, ["loc", [null, [11, 4], [76, 22]]]], ["block", "liquid-if", [["get", "forgottenPassword", ["loc", [null, [77, 17], [77, 34]]]]], [], 1, null, ["loc", [null, [77, 4], [99, 18]]]], ["block", "liquid-unless", [["get", "forgottenPassword", ["loc", [null, [103, 21], [103, 38]]]]], [], 2, null, ["loc", [null, [103, 4], [109, 22]]]], ["block", "liquid-if", [["get", "forgottenPassword", ["loc", [null, [110, 17], [110, 34]]]]], [], 3, null, ["loc", [null, [110, 4], [112, 18]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});