define('dispel-client/models/subscription', ['exports', 'ember-data'], function (exports, _emberData) {
  var Subscription;

  Subscription = _emberData['default'].Model.extend({
    users: _emberData['default'].hasMany('users', {
      async: true
    }),
    name: _emberData['default'].attr('string'),
    computing_minute_allowance: _emberData['default'].attr('number'),
    bandwidth_allowance: _emberData['default'].attr('number'),
    computing_overage_price: _emberData['default'].attr('number'),
    bandwidth_overage_price: _emberData['default'].attr('number'),
    monthly_price: _emberData['default'].attr('number'),
    description: _emberData['default'].attr('string')
  });

  exports['default'] = Subscription;
});