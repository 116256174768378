define('dispel-client/validators/local/match', ['exports', 'ember', 'ember-validations/validators/base', 'ember-validations/messages'], function (exports, _ember, _emberValidationsValidatorsBase, _emberValidationsMessages) {
  var emailValidation;

  emailValidation = _emberValidationsValidatorsBase['default'].extend({
    init: function init() {
      this._super();
      this.dependentValidationKeys.pushObject(this.options.first);
      return this.dependentValidationKeys.pushObject(this.options.second);
    },
    call: function call() {
      var first, second;
      first = this.model.get(this.options.first);
      second = this.model.get(this.options.second);
      if (first !== second) {
        return this.errors.pushObject('Your passwords do not match');
      }
    }
  });

  exports['default'] = emailValidation;
});