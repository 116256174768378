define('dispel-client/helpers/trial-desktop', ['exports', 'ember'], function (exports, _ember) {
  var TrialDesktopHelper, trialDesktop;

  exports.trialDesktop = trialDesktop = function (params) {
    var computing_minute_allowance, subscription;
    subscription = params[0];
    computing_minute_allowance = (subscription.get('computing_minute_allowance') / 60).toString();
    return computing_minute_allowance;
  };

  TrialDesktopHelper = _ember['default'].HTMLBars.makeBoundHelper(trialDesktop);

  exports.trialDesktop = trialDesktop;
  exports['default'] = TrialDesktopHelper;
});