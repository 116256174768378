define('dispel-client/features/account/controller', ['exports', 'ember'], function (exports, _ember) {
  var AccountController;

  AccountController = _ember['default'].Controller.extend({
    pageGlyphicon: 'glyphicon-cog',
    pageGlyphiconColor: 'cyan',
    pageColor: 'cyan-title',
    pageName: (function () {
      var _email, _user;
      _user = this.get('model.user');
      _email = _user.get('email');
      return 'Account info for ' + _email;
    }).property('model'),
    newPassword: null,
    emailConfirmation: null,
    minimumPasswordLength: 10,
    producingReceipts: (function () {
      if (this.get('model.make_receipts') === true) {
        return "yes";
      } else {
        return "no";
      }
    }).property('producingReceipts'),
    actions: {
      provideIntercomSuggestion: function provideIntercomSuggestion() {
        return window.Intercom('showNewMessage');
      }
    }
  });

  exports['default'] = AccountController;
});