define('dispel-client/helpers/desktop-usage', ['exports', 'ember'], function (exports, _ember) {
  var DesktopUsageHelper, desktopUsage;

  exports.desktopUsage = desktopUsage = function (params) {
    var allowance_hours, computing_minutes_used, credit_reserve, desktop_allowance, percent_used, subscription_desktop_allowance, subscription_id, subscriptions, used_hours;
    subscriptions = params[0];
    subscription_id = params[1].toString();
    computing_minutes_used = params[2];
    credit_reserve = params[3];
    subscription_desktop_allowance = subscriptions.findBy('id', subscription_id).get('computing_minute_allowance');
    if (credit_reserve) {
      desktop_allowance = parseFloat(subscription_desktop_allowance) + parseFloat(120 * credit_reserve);
    } else {
      desktop_allowance = parseFloat(subscription_desktop_allowance);
    }
    used_hours = Math.round(100 * computing_minutes_used / 60) / 100;
    allowance_hours = Math.round(desktop_allowance / 60);
    percent_used = Math.floor(10000 * computing_minutes_used / desktop_allowance) / 100;
    return "You have used " + percent_used.toString() + "% of your total time (" + used_hours.toString() + " hrs / " + allowance_hours.toString() + " hrs)";
  };

  DesktopUsageHelper = _ember['default'].HTMLBars.makeBoundHelper(desktopUsage);

  exports.desktopUsage = desktopUsage;
  exports['default'] = DesktopUsageHelper;
});