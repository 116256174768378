define('dispel-client/helpers/connection-usage', ['exports', 'ember'], function (exports, _ember) {
  var ConnectionUsageHelper, connectionUsage;

  exports.connectionUsage = connectionUsage = function (params) {
    var allowance_GB, bandwidth_allowance, bandwidth_used, credit_reserve, percent_used, subscription_bandwidth_allowance, subscription_id, subscriptions, used_GB;
    subscriptions = params[0];
    subscription_id = params[1].toString();
    bandwidth_used = params[2];
    credit_reserve = params[3];
    subscription_bandwidth_allowance = subscriptions.findBy('id', subscription_id).get('bandwidth_allowance');
    if (credit_reserve) {
      bandwidth_allowance = parseFloat(1000 * subscription_bandwidth_allowance) + parseFloat(10000 * credit_reserve);
    } else {
      bandwidth_allowance = parseFloat(1000 * subscription_bandwidth_allowance);
    }
    used_GB = Math.round(bandwidth_used / 10) / 100;
    allowance_GB = Math.round(bandwidth_allowance / 1000);
    percent_used = Math.floor(10000 * bandwidth_used / bandwidth_allowance) / 100;
    return "You have used " + percent_used.toString() + "% of your total bandwidth (" + used_GB.toString() + " GB / " + allowance_GB.toString() + " GB)";
  };

  ConnectionUsageHelper = _ember['default'].HTMLBars.makeBoundHelper(connectionUsage);

  exports.connectionUsage = connectionUsage;
  exports['default'] = ConnectionUsageHelper;
});