define('dispel-client/features/signup/plans/controller', ['exports', 'ember'], function (exports, _ember) {
  var SignupPlansController;

  SignupPlansController = _ember['default'].Controller.extend({
    subscriptionChosen: false,
    signup: _ember['default'].inject.controller(),
    subscriptionName: _ember['default'].computed.alias('signup.subscriptionName'),
    subscriptionPrice: _ember['default'].computed.alias('signup.subscriptionPrice'),
    trialPlanSelected: _ember['default'].computed.alias('signup.trialPlanSelected'),
    planTabComplete: _ember['default'].computed.alias('signup.planTabComplete'),
    paymentTabAvailable: _ember['default'].computed.alias('signup.paymentTabAvailable'),
    trialSubscription: false,
    trialSubscription: (function () {
      var _trialPlanSelected;
      _trialPlanSelected = this.get('signup.trialPlanSelected');
      if (_trialPlanSelected) {
        this.set('model.user.overage_autocharge_permission', false);
        return true;
      } else {
        this.set('model.user.overage_autocharge_permission', true);
        return false;
      }
    }).property('trialPlanSelected'),
    setCurrentTabState: _ember['default'].on('init', function () {
      return _ember['default'].$('.plansTabAnchor').click();
    }),
    sortedPlans: _ember['default'].computed('model', function () {
      var _plans, _user;
      _user = this.get('model.user');
      _plans = this.get('model.plans');
      _plans.forEach((function (_this) {
        return function (value, index) {
          var _monthly_price, _noPlan, _userSelectedPlan;
          _monthly_price = value.get('monthly_price');
          value.set('monthly_price', parseInt(_monthly_price));
          if (value.get('name') !== 'No-Plan') {
            value.set('show', true);
          }
          _noPlan = _this.get('model.plans').findBy('name', "No-Plan");
          if (parseInt(_this.get('model.user.subscription_id')) !== parseInt(_noPlan.id)) {
            _userSelectedPlan = _this.get('model.plans').findBy('id', _this.get('model.user.subscription_id').toString());
            if (_userSelectedPlan.id === value.get('id')) {
              value.set('enabled', true);
              _this.set('subscriptionChosen', true);
              _this.set('model.user.subscription_id', value.get('id'));
              _this.set('model.subscription', value.get('id'));
              _this.set('subscriptionName', value.get('name'));
              return _this.set('subscriptionPrice', parseInt(value.get('monthly_price')));
            } else {
              return value.set('enabled', false);
            }
          } else {
            if (value.get('name') === 'Trial') {
              value.set('enabled', true);
              _this.set('subscriptionChosen', true);
              _this.set('model.user.subscription_id', value.get('id'));
              _this.set('model.subscription', 6);
              _this.set('trialPlanSelected', true);
              _this.set('subscriptionName', value.get('name'));
              return _this.set('subscriptionPrice', parseInt(value.get('monthly_price')));
            } else {
              return value.set('enabled', false);
            }
          }
        };
      })(this));
      return _ember['default'].ArrayProxy.extend(_ember['default'].SortableMixin).create({
        sortProperties: ['monthly_price'],
        sortAscending: true,
        content: this.get('model.plans')
      });
    }),
    planEnabled: function planEnabled() {
      var _plans, _user, _userSubscription;
      _user = this.get('model.user');
      _userSubscription = this.get('model.subscription');
      _plans = this.get('model.plans');
      this.set('subscriptionChosen', false);
      _plans.forEach((function (_this) {
        return function (value, index) {
          if (value.id === _userSubscription.id) {
            value.set('enabled', true);
            return _this.set('subscriptionChosen', true);
          } else {
            return value.set('enabled', false);
          }
        };
      })(this));
      if (_userSubscription.id === '6') {
        return this.set('trialPlanSelected', true);
      } else {
        return this.set('trialPlanSelected', false);
      }
    },
    planSubmittable: (function () {
      if (this.subscriptionChosen) {
        Materialize.toast("Plan selected.", 3000);
        this.set('subscriptionChosen', true);
        this.set('planButtonStatus', 'signup--btn--robinBlue signup--btn');
        this.notifyPropertyChange('subscriptionName');
        return true;
      } else {
        this.set('subscriptionChosen', false);
        this.set('planButtonStatus', 'disabled');
        this.notifyPropertyChange('subscriptionName');
        return false;
      }
    }).observes('subscriptionChosen'),
    actions: {
      choosePlan: function choosePlan(plan, user, subscription) {
        var name, price;
        this.set('model.user.subscription_signup_date', new Date());
        if (user.subscription_id !== null) {
          name = plan.get('name');
          price = plan.get('monthly_price');
          this.set('model.user.subscription_id', plan.id);
          this.set('subscriptionName', name);
          this.set('subscriptionPrice', parseInt(price));
          this.set('model.subscription', plan);
          return this.planEnabled();
        }
      },
      showCreditInformation: function showCreditInformation() {
        return swal({
          title: "What happens if I run over?",
          text: "<p>If you use up your monthly credits, we'll buy you credits in $10 increments to deduct against. Any leftovers will be credited against next month's subscription price.</p><p>If we don't have your permission to buy more credits, we'll have to shut down your service until you are able to log into your Dispel account and purchase more.</p><p>Not to worry, we'll notify you before you run out.</p>",
          html: true
        });
      },
      toggleCreditCharge: function toggleCreditCharge(user) {
        this.toggleProperty('model.user.overage_autocharge_permission');
        if (this.get('model.user.overage_autocharge_permission')) {
          return Materialize.toast("<i class='material-icons tiny'>check_circle</i> You'll enjoy uninterrupted service if you go over.", 4000, 'light-blue');
        } else {
          return Materialize.toast("<i class='material-icons tiny'>warning</i> If you go over your plan's limits,<br>you will lose service until next billing cycle.", 4000, 'amber black-text');
        }
      },
      setPlan: function setPlan() {
        this.set('planTabComplete', true);
        this.set('paymentTabAvailable', '');
        return _ember['default'].run.next(function () {
          _ember['default'].$('#payment').click();
          return this.transitionToRoute('signup.payment');
        });
      }
    }
  });

  exports['default'] = SignupPlansController;
});