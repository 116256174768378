define('dispel-client/features/index/route', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin) {
  var IndexRoute;

  IndexRoute = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], {
    redirect: function redirect(model, transition) {
      return this.transitionTo('service');
    }
  });

  exports['default'] = IndexRoute;
});