define('dispel-client/features/signup/done/controller', ['exports', 'ember'], function (exports, _ember) {
  var SignupDoneController;

  SignupDoneController = _ember['default'].Controller.extend({
    signup: _ember['default'].inject.controller(),
    subscriptionName: _ember['default'].computed.alias('signup.subscriptionName'),
    subscriptionPrice: _ember['default'].computed.alias('signup.subscriptionPrice'),
    trialPlanSelected: _ember['default'].computed.alias('signup.trialPlanSelected'),
    trialSelectedAndNoCC: _ember['default'].computed('trialPlanSelected', 'model.user.pending_credit_card.first_name', function () {
      var ccData, trial;
      trial = this.get('trialPlanSelected');
      ccData = this.get('model.user.pending_credit_card.first_name');
      if (trial && ccData) {
        return false;
      }
      if (trial && !ccData) {
        return true;
      }
      if (!trial) {
        return false;
      }
    }),
    threeDayCalculation: moment().add(3, 'days').format('dddd, MMMM Mo, YYYY'),
    actions: {
      openModal: function openModal() {
        var _targetModal, modal;
        _targetModal = _ember['default'].$(event.target).attr('href');
        modal = _ember['default'].$(_targetModal);
        return modal.openModal({
          dismissible: true,
          opacity: .5,
          in_duration: 300,
          out_duration: 200
        });
      },
      toggleCreditCharge: function toggleCreditCharge(user) {
        this.toggleProperty('model.user.overage_autocharge_permission');
        if (this.get('model.user.overage_autocharge_permission')) {
          return Materialize.toast("<i class='material-icons tiny'>check_circle</i> You'll enjoy uninterrupted service if you go over.", 4000, 'light-blue');
        } else {
          return Materialize.toast("<i class='material-icons tiny'>warning</i> If you go over your plan's limits,<br>you will lose service until next billing cycle.", 4000, 'amber black-text');
        }
      },
      showCreditInformation: function showCreditInformation() {
        return swal({
          title: "What happens if I run over?",
          text: "<p>If you use up your monthly credits, we'll buy you credits in $10 increments to deduct against. Any leftovers will be credited against next month's subscription price.</p><p>If we don't have your permission to buy more credits, we'll have to shut down your service until you are able to log into your Dispel account and purchase more.</p><p>Not to worry, we'll notify you before you run out.</p>",
          html: true
        });
      },
      completeSignup: function completeSignup() {
        var _text;
        if (this.get('trialPlanSelected')) {
          _text = "<p> Welcome to <strong>Dispel</strong>, and thank you for signing up!</p><p> You are taking advantage of a Free Trial, <strong>you will not be charged</strong> at this time. You will be charged $19/mo for the <em>Standard Plan</em> when your trial expires (unless you cancel your subscription by 5pm EST the day before your trial ends).</p><p>By proceeding to create your account and use Dispel, you are agreeing to our <a href='https://dispel.io/legal/terms-of-use/' target='_blank'>Terms of Use</a>, <a href='https://dispel.io/legal/privacy-policy/' target='_blank'>Privacy Policy</a>, and <a href='https://dispel.io/legal/end-user-license-agreement/' target='_blank'>End User License Agreement</a>.</p>";
        } else {
          _text = "<p> You are signing up for a paid subscription, <strong>your credit card will be charged</strong> at this time. As a thank you for subscribing, we are happy to give you an extra <em>5GB of Invisible Connections</em>. If you don't use that credit, we will discount your next monthly charge!</p><p>By proceeding to create your account and use Dispel, you are agreeing to our <a href='https://dispel.io/legal/terms-of-use/' target='_blank'>Terms of Use</a>, <a href='https://dispel.io/legal/privacy-policy/' target='_blank'>Privacy Policy</a>, and <a href='https://dispel.io/legal/end-user-license-agreement/' target='_blank'>End User License Agreement</a>.</p>";
        }
        return swal({
          title: "Finish Signing Up",
          type: "success",
          text: _text,
          imageUrl: "",
          showCancelButton: true,
          confirmButtonColor: '#08bcff',
          confirmButtonText: 'I Agree',
          cancelButtonText: 'Go back',
          html: true,
          closeOnConfirm: true,
          closeOnCancel: true,
          allowOutsideClick: true
        }, (function (_this) {
          return function (isConfirm) {
            var $_doneButton, _newUser, _self, _user;
            if (isConfirm) {
              _user = _this.get('model.user');
              _self = _this;
              $_doneButton = $('.signup--done--submit');
              _this.set('submitting', true);
              _this.set('model.user.accepted_terms_of_service', true);
              _this.set('model.user.accepted_privacy_policy', true);
              if (_user.get('isNew') && _user.get('hasDirtyAttributes')) {
                $_doneButton.html('');
                $_doneButton.addClass('btn-loading');
                Materialize.toast("<i class='material-icons rotating'>autorenew</i> Submitting your new account information", 4000, 'light-blue');
                _newUser = {
                  email: _user.get('email'),
                  password: _user.get('password'),
                  password_confirmation: _user.get('password_confirmation'),
                  accepted_terms_of_service: _user.get('accepted_terms_of_service'),
                  accepted_privacy_policy: _user.get('accepted_privacy_policy'),
                  subscription_id: _user.get('subscription_id'),
                  overage_autocharge_permission: _user.get('overage_autocharge_permission'),
                  pending_credit_card: _user.get('pending_credit_card'),
                  prior_coupon: void 0,
                  referred_from: _user.get('referred_from')
                };
              }
              if (!_user.get('pending_credit_card.number')) {
                Materialize.toast("A valid credit card is required to complete signup.", 4000, 'red');
                Raven.captureException(new Error('User attempted to signup without a valid CC'));
              }
              return _ember['default'].$.post(_this.config.Variables.API_URL + '/signup/', _newUser).then(function (response) {
                Materialize.toast("<i class='material-icons'>check_circle</i> Account registered!", 4000, 'green');
                return _self.transitionToRoute('signup.verify');
              }, function (error) {
                Materialize.toast("Our servers failed to register this change.<br>With deep self-loathing, we apologize and ask forgiveness.<br>Please  try again later, and let us know.", 4000, 'red');
                return Raven.captureException(new Error('Signup verification failed'), {
                  extra: {
                    returnedObj: returnedObj
                  }
                });
              });
            }
          };
        })(this));
      }
    }
  });

  exports['default'] = SignupDoneController;
});