define('dispel-client/features/forgot-password/controller', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
  var ForgotPasswordController;

  ForgotPasswordController = _ember['default'].Controller.extend(_emberValidations['default'], {
    queryParams: ['verification'],
    verification: null,
    passwordsMatch: false,
    loginMessage: '',
    forgottenPassword: false,
    password: void 0,
    passwordError: '',
    confirmPassword: void 0,
    confirmPasswordError: '',
    submitDisabled: false,
    urlPrefix: (function () {
      return this.config.Variables.API_URL + '/' + this.config.Variables.URL_NAMESPACE;
    }).property(),
    validations: {
      password: {
        password: true
      },
      confirmPassword: {
        match: {
          first: 'password',
          second: 'confirmPassword'
        }
      }
    },
    validateInputs: _ember['default'].observer('password', 'confirmPassword', function () {
      var _confirm, _confirmErr, _passErr, _password;
      _password = this.get('password');
      _passErr = this.get('errors.password');
      _confirm = this.get('confirmPassword');
      _confirmErr = this.get('errors.confirmPassword');
      if (_password && _password.length) {
        this.set('passwordError', _passErr[0]);
      }
      if (_confirm && _confirm.length) {
        this.set('confirmPasswordError', _confirmErr[0]);
      }
      if (!_passErr.length && _password.length && !_confirmErr.length && _confirm) {
        return this.set('submitDisabled', true);
      } else {
        return this.set('submitDisabled', false);
      }
    }),
    actions: {
      toggleForgotPassword: function toggleForgotPassword() {
        var _forgottenPassword;
        _forgottenPassword = this.get('forgottenPassword');
        _forgottenPassword = !_forgottenPassword;
        return this.set('forgottenPassword', _forgottenPassword);
      },
      resetPassword: function resetPassword() {
        var _, data, i, numberOfPaddingToAdd, padding, ref, rest;
        data = this.getProperties('password', 'confirmPassword');
        data.verification = this.get('verification').replace(/ /g, '+');
        rest = data.verification.length % 4;
        padding = "";
        if (rest !== 0) {
          numberOfPaddingToAdd = 4 - rest;
          for (_ = i = 0, ref = numberOfPaddingToAdd; 0 <= ref ? i < ref : i > ref; _ = 0 <= ref ? ++i : --i) {
            padding += "=";
          }
        }
        data.verification += padding;
        return _ember['default'].$.post(this.config.Variables.API_URL + '/reset-password', data).fail((function (_this) {
          return function (err) {
            err = JSON.parse(err);
            return Materialize.toast("If the account exists, the password has been updated.<i class='material-icons green-text'>check_circle</i>", 4000);
          };
        })(this)).done((function (_this) {
          return function (res) {
            if (res.error) {
              return Materialize.toast("Passwords did not match", 3500, 'red');
            } else {
              Materialize.toast("If the account exists, the password has been updated.<i class='material-icons green-text'>check_circle</i>", 3500);
              return _this.transitionTo('login');
            }
          };
        })(this));
      }
    }
  });

  exports['default'] = ForgotPasswordController;
});