define('dispel-client/router', ['exports', 'ember', 'dispel-client/config/environment'], function (exports, _ember, _dispelClientConfigEnvironment) {
  var Router;

  Router = _ember['default'].Router.extend({
    location: _dispelClientConfigEnvironment['default'].locationType
  });

  Router.reopen({
    notifyIntercom: (function () {
      Intercom('update');
    }).on('didTransition')
  });

  Router.map(function () {
    this.route('login', function () {});
    this.route('service');
    this.route('billing', function () {
      this.route('overview');
      this.route('plans');
      this.route('cards');
      this.route('receipts');
      this.route('receipt', {
        path: 'receipt/:id'
      });
    });
    this.route('account', function () {
      this.route('settings');
      this.route('notifications');
      return this.route('usage');
    });
    this.route('data');
    this.route('privacy-policy');
    this.route('terms-of-service');
    this.route('end-user-license-agreement');
    this.route('forgot-password');
  });

  exports['default'] = Router;
});