define('dispel-client/features/signup/payment/controller', ['exports', 'ember'], function (exports, _ember) {
  var SignupPaymentController;

  SignupPaymentController = _ember['default'].Controller.extend({
    signup: _ember['default'].inject.controller(),
    doneTabAvailable: _ember['default'].computed.alias('signup.doneTabAvailable'),
    paymentTabComplete: _ember['default'].computed.alias('signup.paymentTabComplete'),
    trialPlanSelected: _ember['default'].computed.alias('signup.trialPlanSelected'),
    threeDayCalculation: moment().add(3, 'days').format('dddd, MMMM Mo, YYYY'),
    actions: {
      cardEntered: function cardEntered() {
        this.set('paymentTabComplete', true);
        this.set('doneTabAvailable', '');
        return _ember['default'].run.next(function () {
          _ember['default'].$('#done').click();
          return this.transitionToRoute('signup.done');
        });
      }
    }
  });

  exports['default'] = SignupPaymentController;
});