define('dispel-client/helpers/service-type', ['exports', 'ember'], function (exports, _ember) {
  var ServiceTypeHelper, serviceType;

  exports.serviceType = serviceType = function (data) {
    var serviceId, services;
    serviceId = data[0].toString();
    services = data[1];
    return services.findBy('id', serviceId).get('friendly_name');
  };

  ServiceTypeHelper = _ember['default'].Helper.helper(serviceType);

  exports.serviceType = serviceType;
  exports['default'] = ServiceTypeHelper;
});