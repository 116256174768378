define('dispel-client/initializers/config', ['exports', 'dispel-client/config/environment'], function (exports, _dispelClientConfigEnvironment) {
  var ConfigInitializer, initialize;

  exports.initialize = initialize = function (container, app) {
    var config;
    config = {
      config: _dispelClientConfigEnvironment['default']
    };
    app.register('config:main', config.config, {
      instantiate: false
    });
    app.inject('route', 'config', 'config:main');
    app.inject('controller', 'config', 'config:main');
    app.inject('component', 'config', 'config:main');
  };

  ConfigInitializer = {
    name: 'config',
    initialize: initialize
  };

  exports.initialize = initialize;
  exports['default'] = ConfigInitializer;
});