define('dispel-client/validators/local/email', ['exports', 'ember', 'ember-validations/validators/base'], function (exports, _ember, _emberValidationsValidatorsBase) {
  var emailValidation;

  emailValidation = _emberValidationsValidatorsBase['default'].extend({
    call: function call() {
      if (!_ember['default'].isEmpty(this.model.get(this.property))) {
        if (!/.+@.+\..{2,4}/.test(this.model.get(this.property))) {
          this.errors.pushObject('You must have a valid email.');
        }
      }
    }
  });

  exports['default'] = emailValidation;
});