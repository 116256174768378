define('dispel-client/features/components/credit-card/component', ['exports', 'ember', 'ember-credit-cards/utils/validations', 'ember-credit-cards/utils/cards'], function (exports, _ember, _emberCreditCardsUtilsValidations, _emberCreditCardsUtilsCards) {
  var CreditCardComponent;

  CreditCardComponent = _ember['default'].Component.extend({
    addCard: {
      expire_month: moment().format('MM'),
      expire_year: moment().format('YYYY'),
      type: ''
    },
    addCardText: 'Add Card',
    currentMonth: void 0,
    addCardBtnState: 'disabled',
    buttonStatus: 'btn-robinBlue--action',
    addCardBtnDisabledState: true,
    urlPrefix: (function () {
      return this.config.Variables.API_URL + '/' + this.config.Variables.URL_NAMESPACE;
    }).property(),
    ccMonths: (function () {
      return this.setCardMonths();
    }).property(),
    ccYears: (function () {
      return this.setCardYears();
    }).property(),
    setCardMonths: function setCardMonths() {
      var currentMonth, months;
      months = [{
        value: '01',
        name: 'January-01',
        selected: false
      }, {
        value: '02',
        name: 'February-02',
        selected: false
      }, {
        value: '03',
        name: 'March-03',
        selected: false
      }, {
        value: '04',
        name: 'April-04',
        selected: false
      }, {
        value: '05',
        name: 'May-05',
        selected: false
      }, {
        value: '06',
        name: 'June-06',
        selected: false
      }, {
        value: '07',
        name: 'July-07',
        selected: false
      }, {
        value: '08',
        name: 'August-08',
        selected: false
      }, {
        value: '09',
        name: 'September-09',
        selected: false
      }, {
        value: '10',
        name: 'October-10',
        selected: false
      }, {
        value: '11',
        name: 'November-11',
        selected: false
      }, {
        value: '12',
        name: 'December-12',
        selected: false
      }];
      currentMonth = new Date().getMonth();
      months[currentMonth].selected = true;
      this.set('addCard.expire_month', months[currentMonth].value);
      return months;
    },
    setCardYears: function setCardYears() {
      var end, start, years;
      years = [];
      start = new Date().getFullYear();
      end = new Date().getFullYear() + 20;
      while (start < end) {
        years.push(start++);
      }
      return years;
    },
    validateForm: function validateForm() {
      var form, invalidFields, validOverride;
      invalidFields = 0;
      validOverride = false;
      form = this.get('addCard');
      if (!form.first_name || !form.first_name.trim().length) {
        invalidFields++;
      }
      if (!form.last_name || !form.last_name.trim().length) {
        invalidFields++;
      }
      if (!_emberCreditCardsUtilsValidations['default'].validateNumber(form.number)) {
        invalidFields++;
        if (form.number && form.number.trim().length) {
          validOverride = true;
        }
      }
      if (!_emberCreditCardsUtilsValidations['default'].validateCVC(form.cvv2)) {
        invalidFields++;
        if (form.cvv2 && form.cvv2.trim().length) {
          validOverride = true;
        }
      }
      if (invalidFields === 0) {
        this.set('addCardBtnState', '');
        this.set('addCardBtnDisabledState', false);
        return this.set('addCardText', 'Add Card');
      } else {
        this.set('addCardBtnState', 'disabled');
        this.set('addCardBtnDisabledState', true);
        return this.set('addCardText', 'Add Card');
      }
    },
    setCardType: function setCardType() {
      var card;
      card = this.get('addCard.number');
      if (card) {
        this.set('addCard.type', _emberCreditCardsUtilsCards['default'].cardType(card));
      } else {
        return this.set('addCard.type', '');
      }
    },
    didInsertElement: function didInsertElement() {
      $('.newCardForm input').keyup((function (_this) {
        return function (e) {
          _this.validateForm();
          return _this.setCardType();
        };
      })(this));
      $('.newCardForm select').change((function (_this) {
        return function (e) {
          _this.validateForm();
          return _this.setCardType();
        };
      })(this));
    },
    resetForm: function resetForm() {
      var form, self;
      form = this.get('addCard');
      self = this;
      return $.each(form, function (index, value) {
        if (index === 'expire_year') {} else if (index === 'expire_month') {} else {
          return self.set('addCard.' + index, null);
        }
      });
    },
    actions: {
      addCard: function addCard() {
        var formData;
        formData = this.get('addCard');
        if (this.get('model.user').get('id') === null) {
          this.set('model.user.pending_credit_card', void 0);
          this.set('model.user.pending_credit_card', formData);
          return this.sendAction();
        } else {
          Materialize.toast("Adding your credit card...", 3500);
          return _ember['default'].$.post(this.get('urlPrefix') + '/save-credit-card', {
            cardData: formData
          }).fail((function (_this) {
            return function (err) {
              err = JSON.parse(err);
              console.log('SAVED CARD FAIL', err);
              return Materialize.toast("Sorry, we could not save your card. Our developers have been notified.", 6000, 'red accent-2');
            };
          })(this)).done((function (_this) {
            return function (res) {
              res = JSON.parse(res);
              Materialize.toast("Your card was successfully added! <i class='material-icons green-text'>check_circle</i>", 3500);
              _this.sendAction();
              return _this.resetForm();
            };
          })(this));
        }
      }
    }
  });

  exports['default'] = CreditCardComponent;
});