define('dispel-client/features/components/update-password/component', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
  var UpdatePasswordComponent;

  UpdatePasswordComponent = _ember['default'].Component.extend(_emberValidations['default'], {
    $form: void 0,
    validNewPassword: false,
    buttonStatus: 'btn disabled',
    password: void 0,
    passwordError: '',
    confirmPassword: void 0,
    confirmPasswordError: '',
    validations: {
      password: {
        password: true
      },
      confirmPassword: {
        match: {
          first: 'password',
          second: 'confirmPassword'
        }
      }
    },
    validateInputs: _ember['default'].observer('password', 'confirmPassword', function () {
      var _confirm, _confirmErr, _passErr, _password;
      _password = this.get('password');
      _passErr = this.get('errors.password');
      _confirm = this.get('confirmPassword');
      _confirmErr = this.get('errors.confirmPassword');
      if (_password && _password.length) {
        this.set('passwordError', _passErr[0]);
      }
      if (_confirm && _confirm.length) {
        this.set('confirmPasswordError', _confirmErr[0]);
      }
      if (!_passErr.length && _password.length && !_confirmErr.length && _confirm.length) {
        this.set('buttonStatus', 'btn-robinBlue');
        return this.set('validNewPassword', true);
      } else {
        this.set('buttonStatus', 'btn disabled');
        return this.set('validNewPassword', false);
      }
    }),
    actions: {
      updatePassword: function updatePassword() {
        var _onFail, _onSuccess, _updatePasswordRequest, _updatedPassword, user;
        if (this.currentPassword.length && this.password.length && !this.validNewPassword) {
          Materialize.toast("Please enter a valid password.", 3500);
        }
        if (this.validNewPassword) {
          if (this.currentPassword === this.password) {
            Materialize.toast("That's the same password.", 3500);
            return false;
          } else {
            _updatedPassword = {
              user: {
                password: this.currentPassword,
                newPassword: this.password
              }
            };
            user = this.get('user');
            Materialize.toast("Updating your password. <i class='material-icons rotating'>autorenew</i>", 3500);
            _updatePasswordRequest = _ember['default'].$.ajax({
              url: this.config.Variables.API_URL + '/api/users/' + user.id,
              method: 'put',
              data: _updatedPassword,
              header: "Bearer " + this.get('session.secure.token')
            });
            _updatePasswordRequest.done((function (_this) {
              return function (_response) {
                if (_response.error) {
                  return _onFail(_response);
                } else {
                  return _onSuccess(_response);
                }
              };
            })(this));
            _onSuccess = (function (_this) {
              return function (response) {
                var _successMessage;
                _successMessage = "Successfully updated your password!";
                Materialize.toast(_successMessage, 4500, 'green');
                _this.set('currentPassword', '');
                _this.set('password', '');
                _this.set('confirmPassword', '');
                return _this.set('validNewPassword', false);
              };
            })(this);
            _onFail = (function (_this) {
              return function (returnedObj) {
                var error;
                error = {
                  message: "The current password you entered is incorrect."
                };
                Materialize.toast(error.message, 4000, 'red');
                return _this.$currentPassword.focus();
              };
            })(this);
          }
          return false;
        } else {
          Materialize.toast("Please enter your current password and a new password.", 3500);
          return false;
        }
      }
    }
  });

  exports['default'] = UpdatePasswordComponent;
});