define('dispel-client/initializers/md-settings', ['exports', 'dispel-client/config/environment', 'ember-cli-materialize/services/md-settings'], function (exports, _dispelClientConfigEnvironment, _emberCliMaterializeServicesMdSettings) {
  exports.initialize = initialize;

  function initialize(container, application) {
    var materializeDefaults = _dispelClientConfigEnvironment['default'].materializeDefaults;

    application.register('config:materialize', materializeDefaults, { instantiate: false });
    application.register('service:materialize-settings', _emberCliMaterializeServicesMdSettings['default']);
    application.inject('service:materialize-settings', 'materializeDefaults', 'config:materialize');
  }

  exports['default'] = {
    name: 'md-settings',
    initialize: initialize
  };
});