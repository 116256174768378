define('dispel-client/features/account/route', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin) {
  var AccountRoute;

  AccountRoute = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], {
    titleToken: 'Account',
    redirect: function redirect(model, transition) {
      if (transition.targetName === 'account.index') {
        return this.transitionTo('account.settings');
      }
    },
    model: function model() {
      return _ember['default'].RSVP.hash({
        projects: this.store.query('project', {
          user_id: this.get('currentUser.id')
        }),
        services: this.store.peekAll('service'),
        user: this.store.peekRecord('user', this.get('currentUser.id'))
      });
    }
  });

  exports['default'] = AccountRoute;
});