define('dispel-client/models/feedback', ['exports', 'ember-data'], function (exports, _emberData) {
  var Feedback;

  Feedback = _emberData['default'].Model.extend({
    recommend_rating: _emberData['default'].attr('number'),
    future_options: _emberData['default'].attr('string'),
    other_offerings: _emberData['default'].attr('string'),
    proxy_options: _emberData['default'].attr('string'),
    proxy_option_other: _emberData['default'].attr('string'),
    better_experience: _emberData['default'].attr('string'),
    can_contact: _emberData['default'].attr('boolean'),
    created_at: _emberData['default'].attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    project: _emberData['default'].belongsTo('project', {
      async: true
    }),
    user: _emberData['default'].belongsTo('user', {
      async: true
    })
  });

  exports['default'] = Feedback;
});