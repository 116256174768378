define("dispel-client/features/components/service-status/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.10",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 0
          }
        },
        "moduleName": "dispel-client/features/components/service-status/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "valign-wrapper service--system-status--group col s12 m6 l3");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("img");
        dom.setAttribute(el2, "class", "valign service--system-status--image");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "service--system-status--group-title text-purple");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("i");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element0, [5]);
        var element3 = dom.childAt(element2, [2]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element1, 'src');
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
        morphs[2] = dom.createAttrMorph(element2, 'class');
        morphs[3] = dom.createMorphAt(element2, 0, 0);
        morphs[4] = dom.createAttrMorph(element3, 'class');
        morphs[5] = dom.createMorphAt(element3, 0, 0);
        return morphs;
      },
      statements: [["attribute", "src", ["concat", ["/assets/images/services/", ["get", "imageName", ["loc", [null, [2, 83], [2, 92]]]], ".svg"]]], ["content", "name", ["loc", [null, [3, 63], [3, 71]]]], ["attribute", "class", ["concat", ["service--system-status--group-status ", ["get", "color", ["loc", [null, [4, 53], [4, 58]]]], " ", ["get", "additionalClasses", ["loc", [null, [4, 63], [4, 80]]]]]]], ["content", "message", ["loc", [null, [4, 84], [4, 95]]]], ["attribute", "class", ["concat", ["material-icons tiny ", ["get", "symbolClass", ["loc", [null, [4, 128], [4, 139]]]]]]], ["content", "symbol", ["loc", [null, [4, 143], [4, 153]]]]],
      locals: [],
      templates: []
    };
  })());
});