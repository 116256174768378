define('dispel-client/helpers/full-price', ['exports', 'ember'], function (exports, _ember) {
  var FullPriceHelper, fullPrice;

  exports.fullPrice = fullPrice = function (params) {
    var amount_paid, plans, receiptType, receiptTypes, receipt_type_id, subscription_id, subscription_price;
    if (params[0] === null) {
      subscription_id = null;
    } else {
      subscription_id = params[0].toString();
    }
    plans = params[1];
    receiptTypes = params[2];
    receipt_type_id = params[3].toString();
    amount_paid = params[4];
    receiptType = receiptTypes.findBy("id", receipt_type_id).get('type');
    if (subscription_id !== null) {
      subscription_price = plans.findBy("id", subscription_id).get('monthly_price');
    }
    if (receiptType === "Monthly Charge") {
      return subscription_price;
    }
    if (receiptType === "Upgrade") {
      return amount_paid;
    }
    if (receiptType === "Downgrade") {
      return "0";
    }
    if (receiptType === "Credit Increase") {
      return amount_paid;
    }
    if (receiptType === "Cancel Subscription") {
      return "0";
    }
  };

  FullPriceHelper = _ember['default'].HTMLBars.makeBoundHelper(fullPrice);

  exports.fullPrice = fullPrice;
  exports['default'] = FullPriceHelper;
});