define('dispel-client/helpers/net-charge', ['exports', 'ember'], function (exports, _ember) {
  var NetChargeHelper, netCharge;

  exports.netCharge = netCharge = function (params) {
    var amount, netDiscount, plans, subscription_id, subscription_price;
    amount = params[0];
    subscription_id = params[1].toString();
    plans = params[2];
    subscription_price = plans.findBy('id', subscription_id).get('monthly_price');
    netDiscount = (subscription_price - amount).toString();
    return netDiscount;
  };

  NetChargeHelper = _ember['default'].HTMLBars.makeBoundHelper(netCharge);

  exports.netCharge = netCharge;
  exports['default'] = NetChargeHelper;
});