define('dispel-client/helpers/hours-ago', ['exports', 'ember'], function (exports, _ember) {
  var HoursAgoHelper, hoursAgo;

  exports.hoursAgo = hoursAgo = function (value) {
    return moment(value).fromNow();
  };

  HoursAgoHelper = _ember['default'].Helper.helper(hoursAgo);

  exports.hoursAgo = hoursAgo;
  exports['default'] = HoursAgoHelper;
});