define('dispel-client/features/components/login-form/component', ['exports', 'ember'], function (exports, _ember) {
  var LoginFormComponent;

  LoginFormComponent = _ember['default'].Component.extend({
    $loop: void 0,
    $intro: void 0,
    $error: void 0,
    $logo: void 0,
    $password: void 0,
    $confirmPassword: void 0,
    signingUp: false,
    passwordsMatch: false,
    loginMessage: '',
    forgottenPassword: false,
    urlPrefix: (function () {
      return this.config.Variables.API_URL + '/' + this.config.Variables.URL_NAMESPACE;
    }).property(),
    assignVariables: function assignVariables() {
      this.set('$loop', $('.signing-in-loop'));
      this.set('$intro', $('.signing-in-intro'));
      this.set('$error', $('.error-intro'));
      this.set('$logo', $('.logo'));
      return this.set('$password', $('#login--login-manager--password').find('input'));
    },
    showLogin: function showLogin() {
      return $('.login--login-manager--wrapper').addClass('visible');
    },
    didInsertElement: function didInsertElement() {
      this.assignVariables();
      if (this.signingUp) {
        this.setConfirmationPasswordWatcher();
      }
      return setTimeout(this.showLogin, 50);
    },
    resetAnimations: function resetAnimations() {
      this.$logo.addClass('visible');
      this.hideLoginAnimations();
      this.hideErrorAnimations();
    },
    hideLoginAnimations: function hideLoginAnimations() {
      this.$loop.removeClass('visible');
      this.$intro.removeClass('visible');
    },
    hideErrorAnimations: function hideErrorAnimations() {
      this.$error.removeClass('visible');
    },
    hideLogo: function hideLogo() {
      return this.$logo.removeClass('visible');
    },
    showErrorAnimation: function showErrorAnimation() {
      this.resetAnimations();
      this.hideLogo();
    },
    showLoginAnimation: function showLoginAnimation() {
      this.resetAnimations();
      this.$logo.removeClass('visible');
      this.$intro.addClass('visible');
      return setTimeout((function (_this) {
        return function () {
          _this.$loop.addClass('visible');
        };
      })(this), 1500);
    },
    setConfirmationPasswordWatcher: function setConfirmationPasswordWatcher() {
      this.set('$confirmPassword', $('#login--login-manager--confirm-password').find('input'));
      this.$confirmPassword.keyup((function (_this) {
        return function (e) {
          _this.checkPasswords();
          return true;
        };
      })(this));
      this.$confirmPassword.change((function (_this) {
        return function (e) {
          return _this.checkPasswords();
        };
      })(this));
      return this.$confirmPassword.blur((function (_this) {
        return function (e) {
          return _this.checkPasswords();
        };
      })(this));
    },
    checkPasswords: function checkPasswords() {
      if (this.$confirmPassword.val().length && this.$password.val().length) {
        if (this.$confirmPassword.val() === this.$password.val()) {
          this.$confirmPassword.removeClass('invalid');
          this.$confirmPassword.addClass('valid');
          return this.set('passwordsMatch', true);
        }
      } else {
        this.$confirmPassword.addClass('invalid');
        return this.set('passwordsMatch', false);
      }
    },
    validInputs: function validInputs() {
      var _email, _input, _inputs, _password, _validInputs, i, len;
      _email = $('input[type=email]');
      _password = $('input[type=password]');
      _inputs = [_email, _password];
      _validInputs = 0;
      for (i = 0, len = _inputs.length; i < len; i++) {
        _input = _inputs[i];
        if (_input[0].checkValidity()) {
          _validInputs++;
        }
      }
      if (_validInputs === 2) {
        return true;
      } else {
        return false;
      }
    },
    actions: {
      authenticate: function authenticate() {
        var authenticator, data;
        if (this.validInputs()) {
          this.showLoginAnimation();
          Materialize.toast("Signing you in <i class='material-icons rotating'>autorenew</i>", 2500);
          data = this.getProperties('identification', 'password');
          authenticator = 'simple-auth-authenticator:token';
          this.get('session').authenticate(authenticator, data);
        }
        return false;
      },
      showLegal: function showLegal(type) {
        return this.sendAction('showLegal', type);
      },
      toggleForgotPassword: function toggleForgotPassword() {
        var _forgottenPassword;
        _forgottenPassword = this.get('forgottenPassword');
        _forgottenPassword = !_forgottenPassword;
        return this.set('forgottenPassword', _forgottenPassword);
      },
      forgotPassword: function forgotPassword() {
        var data;
        data = this.getProperties('identification');
        return _ember['default'].$.post(this.config.Variables.API_URL + '/forgot-password', data).fail((function (_this) {
          return function (err) {
            err = JSON.parse(err);
            return Materialize.toast("If the account exists, it will be emailed a password-reset token momentarily. <i class='material-icons green-text'>check_circle</i>", 4000);
          };
        })(this)).done((function (_this) {
          return function (res) {
            Materialize.toast("If the account exists, it will be emailed a password-reset token momentarily. <i class='material-icons green-text'>check_circle</i>", 3500);
            res = JSON.parse(res);
            if (res.status === 200) {
              Materialize.toast("If the account exists, it will be emailed a password-reset token momentarily. <i class='material-icons green-text'>check_circle</i>", 3500);
            }
            if (res.status !== 200) {
              return Materialize.toast("If the account exists, it will be emailed a password-reset token momentarily. <i class='material-icons green-text'>check_circle</i>", 4000);
            }
          };
        })(this));
      }
    }
  });

  exports['default'] = LoginFormComponent;
});