define('dispel-client/models/project', ['exports', 'ember-data'], function (exports, _emberData) {
  var Project;

  Project = _emberData['default'].Model.extend({
    service: _emberData['default'].belongsTo('service', {
      async: true
    }),
    started: _emberData['default'].attr('date'),
    stopped: _emberData['default'].attr('date'),
    currently_active: _emberData['default'].attr('boolean'),
    build_status: _emberData['default'].attr('string'),
    created: _emberData['default'].attr('string'),
    pooled: _emberData['default'].attr('string'),
    wheel: _emberData['default'].attr('string'),
    tag: _emberData['default'].attr('string'),
    ip: _emberData['default'].attr('string'),
    port: _emberData['default'].attr('string'),
    username: _emberData['default'].attr('string'),
    password: _emberData['default'].attr('string'),
    job_id: _emberData['default'].attr('string'),
    ldap_username: _emberData['default'].attr('string'),
    ldap_password: _emberData['default'].attr('string'),
    proxyIp: _emberData['default'].attr('string'),
    credits_used: _emberData['default'].attr('number'),
    user: _emberData['default'].belongsTo('user', {
      async: true
    }),
    service_id: _emberData['default'].attr('string'),
    user_id: _emberData['default'].attr('string'),
    broken: _emberData['default'].attr('string'),
    report: _emberData['default'].attr('string'),
    key: _emberData['default'].attr('string')
  });

  exports['default'] = Project;
});