define('dispel-client/helpers/trial-bandwidth', ['exports', 'ember'], function (exports, _ember) {
  var TrialBandwidthHelper, trialBandwidth;

  exports.trialBandwidth = trialBandwidth = function (params) {
    var bandwidth_allowance, subscription;
    subscription = params[0];
    bandwidth_allowance = subscription.get('bandwidth_allowance').toString();
    return bandwidth_allowance;
  };

  TrialBandwidthHelper = _ember['default'].HTMLBars.makeBoundHelper(trialBandwidth);

  exports.trialBandwidth = trialBandwidth;
  exports['default'] = TrialBandwidthHelper;
});