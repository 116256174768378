define("dispel-client/templates/molecules/select-subscription/overage-charge", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.10",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 19,
            "column": 0
          }
        },
        "moduleName": "dispel-client/templates/molecules/select-subscription/overage-charge.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("label");
        dom.setAttribute(el1, "class", "billing-choose-plan--credit-charge--text");
        dom.setAttribute(el1, "for", "filled-in-box");
        var el2 = dom.createTextNode("\n  It's okay to charge me to keep service running if I run over.\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "class", "billing-choose-plan--credit-charge--text billing-choose-plan--link");
        var el2 = dom.createTextNode("\n  What does this mean?\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(fragment, [4]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createElementMorph(element0);
        morphs[2] = dom.createElementMorph(element1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "input", [], ["type", "checkbox", "class", "filled-in", "id", "filled-in-box", "checked", ["subexpr", "@mut", [["get", "model.user.overage_autocharge_permission", ["loc", [null, [5, 10], [5, 50]]]]], [], []]], ["loc", [null, [1, 0], [6, 2]]]], ["element", "action", ["toggleCreditCharge", ["get", "model.user", ["loc", [null, [8, 32], [8, 42]]]]], [], ["loc", [null, [8, 2], [8, 44]]]], ["element", "action", ["showCreditInformation"], [], ["loc", [null, [15, 2], [15, 36]]]]],
      locals: [],
      templates: []
    };
  })());
});