define('dispel-client/features/application/controller', ['exports', 'ember'], function (exports, _ember) {
  var ApplicationController, userEmail;

  ApplicationController = _ember['default'].Controller.extend({
    variables: (function () {
      var vars;
      vars = this.config.Variables;
      return vars;
    }).property(),
    route: (function () {
      var _route;
      _route = this.currentRouteName;
      return _route;
    }).property('route')
  }, userEmail = (function () {
    var _email, _user;
    _user = this.get('model.user');
    _email = _user.get('email');
    return _email;
  }).property('model'), {
    creditAlert: false,
    year: new Date().getFullYear(),
    notificationMessage: _ember['default'].Object.create({
      "class": void 0,
      title: '',
      message: ''
    })
  });

  exports['default'] = ApplicationController;
});