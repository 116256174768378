define('dispel-client/features/components/select-subscription/component', ['exports', 'ember'], function (exports, _ember) {
  var SelectSubscriptionComponent;

  SelectSubscriptionComponent = _ember['default'].Component.extend({
    urlPrefix: (function () {
      return this.config.Variables.API_URL + '/' + this.config.Variables.URL_NAMESPACE;
    }).property(),
    currentStep: 0,
    downgradePlanned: false,
    validSubscription: (function () {
      var _subscription;
      _subscription = this.get('model.subscription');
      if (_subscription.get('name') === 'No-Plan') {
        return false;
      } else {
        return true;
      }
    }).property('model.subscription'),
    sortedPlans: (function () {
      return _ember['default'].ArrayProxy.extend(_ember['default'].SortableMixin).create({
        sortProperties: ['monthly_price'],
        sortAscending: true,
        content: this.get('model.plans')
      });
    }).property('plans'),
    sortedReceipts: (function () {
      return _ember['default'].ArrayProxy.extend(_ember['default'].SortableMixin).create({
        sortProperties: ['created_at'],
        sortAscending: true,
        content: this.get('model.receipts')
      });
    }).property('receipts'),
    planSuspensionRequested: (function () {
      this.planEnabled();
      if (parseInt(this.get('model.user').get('downgrade_requested')) === parseInt(this.get('model.plans').findBy('monthly_price', 0).get('id'))) {
        return true;
      } else {
        return false;
      }
    }).property('model.user'),
    planEnabled: function planEnabled() {
      var _plans, _user, _userSubscription;
      _user = this.get('model.user');
      _userSubscription = this.get('model.user.subscription_id');
      _plans = this.get('model.plans');
      this.set('downgradePlanned', false);
      _plans.forEach((function (_this) {
        return function (value, index) {
          if (parseInt(value.id) === _userSubscription) {
            value.set('enabled', true);
          } else {
            value.set('enabled', false);
          }
          if (parseInt(value.id) === parseInt(_user.get('downgrade_requested')) && parseInt(value.id) !== parseInt(_this.get('model.plans').findBy('monthly_price', 0).get('id'))) {
            value.set('downgradeRequested', true);
            return _this.set('downgradePlanned', true);
          } else {
            return value.set('downgradeRequested', false);
          }
        };
      })(this));
      $('.tooltipped').tooltip('remove');
      return setTimeout((function (_this) {
        return function () {
          $('.tooltipped').tooltip();
        };
      })(this), 200);
    },
    subscriptionSteps: ['molecules/select-subscription/billing-choose-plan', 'molecules/select-subscription/billing-thank-you'],
    subscriptionStep: (function () {
      return this.subscriptionSteps[this.get('currentStep')];
    }).property('currentStep'),
    setNavigation: (function () {
      var currentStep;
      currentStep = this.get('currentStep');
      this.setProperties({
        showNext: currentStep !== this.subscriptionSteps.length - 1 && currentStep !== 0 && currentStep !== 1 && currentStep !== 2,
        showPrev: currentStep !== 0
      });
    }).observes('currentStep'),
    willInsertElement: function willInsertElement() {
      this.calculateUpgradePrices();
      this.planEnabled();
      return setTimeout((function (_this) {
        return function () {
          $('.tooltipped').tooltip();
        };
      })(this), 200);
    },
    calculateUpgradePrices: function calculateUpgradePrices() {
      var _plans, _user, _user_subscription;
      _user = this.get('model.user');
      _user_subscription = this.get('model.subscription');
      _plans = this.get('model.plans');
      return _plans.forEach((function (_this) {
        return function (value, index) {
          var _monthly_price, _price;
          _monthly_price = value.get('monthly_price');
          if (_monthly_price > 0) {
            value.set('show', true);
            _price = _this.determinePrice(value, _user, _user_subscription);
            if (_price > 0) {
              return value.set('upgrade_price', _price);
            }
          }
        };
      })(this));
    },
    determinePrice: function determinePrice(plan, user, subscription) {
      var _changeDatePercentage, _currentDate, _current_day, _current_daysInMonth, _current_month, _current_year, _new_price, _old_price, _price, _remaining, _signupDate, _signup_day, _signup_daysInMonth, _signup_month, _signup_year, _subscriptionMonthPercentage;
      _new_price = plan.get('monthly_price');
      _old_price = subscription.get('monthly_price');
      if (_old_price !== 0) {
        if (_new_price > _old_price) {
          _signupDate = user.get('subscription_signup_date');
          _signup_day = _signupDate.getUTCDate();
          _signup_month = _signupDate.getUTCMonth();
          _signup_year = _signupDate.getUTCFullYear();
          _signup_daysInMonth = this.daysInMonth(_signup_month, _signup_year);
          _currentDate = new Date();
          _current_day = _currentDate.getUTCDate();
          _current_month = _currentDate.getUTCMonth();
          _current_year = _currentDate.getUTCFullYear();
          _current_daysInMonth = this.daysInMonth(_current_month, _current_year);
          _subscriptionMonthPercentage = _signup_day / _signup_daysInMonth;
          _changeDatePercentage = _current_day / _current_daysInMonth;
          if (_changeDatePercentage > _subscriptionMonthPercentage) {
            _remaining = 1 + _subscriptionMonthPercentage - _changeDatePercentage;
            _price = _remaining * (_new_price - _old_price);
            return _price = Math.ceil(_price);
          } else {
            if (_subscriptionMonthPercentage === _changeDatePercentage) {
              _remaining = 1;
            } else {
              _remaining = _subscriptionMonthPercentage - _changeDatePercentage;
            }
            _price = _remaining * (_new_price - _old_price);
            return _price = Math.ceil(_price);
          }
        } else {
          return _price = 0;
        }
      } else {
        return _price = 0;
      }
    },
    daysInMonth: function daysInMonth(month, year) {
      var _date, _date_DOM;
      _date = new Date(year, month + 1, 0);
      _date_DOM = _date.getUTCDate();
      return _date_DOM;
    },
    generateReceipt: function generateReceipt(receiptData) {
      _ember['default'].$.post(this.get('urlPrefix') + '/create-receipt', receiptData).fail((function (_this) {
        return function (err) {
          err = JSON.parse(err);
          console.log('Create Receipt Fail', err);
          return Materialize.toast("We could't generate your receipt for this. Please contact us.", 4000, 'red accent-2');
        };
      })(this)).done((function (_this) {
        return function (res) {
          res = JSON.parse(res);
          if (res.status !== 200) {
            return Materialize.toast("We could't generate your receipt for this. Please contact us.", 4000, 'red accent-2');
          }
        };
      })(this));
      return this.deletePendingReceipts();
    },
    deletePendingReceipts: function deletePendingReceipts() {
      var _pendingReceipts, _receipts;
      _receipts = this.get('model.receipts');
      _pendingReceipts = _receipts.filterBy('pending', true);
      return _pendingReceipts.forEach((function (_this) {
        return function (value, index) {
          return value.destroyRecord();
        };
      })(this));
    },
    actions: {
      showCreditInformation: function showCreditInformation() {
        return swal({
          title: "What happens if I run over?",
          text: "<p>If you use up your monthly credits, we'll buy you credits in $10 increments to deduct against. Any leftovers will be credited against next month's subscription price.</p><p>If we don't have your permission to buy more credits, we'll have to shut down your service until you are able to log into your Dispel account and purchase more.</p><p>Not to worry, we'll notify you before you run out.</p>",
          html: true
        });
      },
      advanceStep: function advanceStep(goNext) {
        if (goNext) {
          return this.incrementProperty('currentStep');
        }
        return this.decrementProperty('currentStep');
      },
      choosePlan: function choosePlan(plan, user, subscription) {
        var _buttonText, _message, _price, _title;
        if (user.subscription_id !== null) {
          _price = this.determinePrice(plan, user, subscription);
          if (plan.id === subscription.id) {
            Materialize.toast("That's your current plan!", 3000);
            return false;
          } else {
            if (plan.get('monthly_price') > subscription.get('monthly_price')) {
              if (_price === 0) {
                _title = 'Sign up for the ' + plan.get('name') + " Plan?";
                _message = "You're about to sign up for the " + plan.get('name') + " Plan  for $" + plan.get('monthly_price') + "/month.";
              } else {
                _title = 'Switch to the ' + plan.get('name') + " Plan?";
                _message = "You're about to switch to the " + plan.get('name') + " Plan ($" + plan.get('monthly_price') + "/month) for a prorated $" + _price + " this period.";
              }
            } else if (parseInt(plan.id) === parseInt(user.get('downgrade_requested'))) {
              _title = "Cancel downgrade?";
              _message = "Would you like to cancel your pending downgrade?";
              _buttonText = 'Yes, cancel my downgrade!';
            } else {
              _title = 'Downgrade to the ' + plan.get('name') + " Plan?";
              _message = "You're about to schedule a downgrade to the " + plan.get('name') + " Plan. This will take effect next billing cycle.";
            }
            return swal({
              title: _title,
              text: _message,
              imageUrl: "/assets/images/plans/" + plan.get('name').toLowerCase() + ".svg",
              showCancelButton: true,
              confirmButtonColor: '#08bcff',
              confirmButtonText: _buttonText || 'Yes, switch my plan!',
              cancelButtonText: 'Nevermind',
              closeOnConfirm: false,
              closeOnCancel: true,
              showLoaderOnConfirm: true,
              allowOutsideClick: true
            }, (function (_this) {
              return function (isConfirm) {
                var receiptData, upgradeData;
                if (isConfirm) {
                  if (plan.get('monthly_price') > subscription.get('monthly_price')) {
                    upgradeData = {
                      new_subscription: plan.get('id')
                    };
                    swal({
                      title: 'Processing your payment',
                      text: "<p>Please wait a moment while we process your upgrade.</p>",
                      imageUrl: "/assets/animations/console-loop.gif",
                      html: true,
                      allowEscapeKey: false,
                      allowOutsideClick: false,
                      closeOnConfirm: false,
                      showConfirmButton: false
                    });
                    _ember['default'].$.post(_this.get('urlPrefix') + '/upgrade-subscription', upgradeData).fail(function (err) {
                      return swal({
                        title: "Oops!",
                        text: "Your payment failed to process, please <a href='mailto:support@dispel.io'>contact us</a> to resolve the issue.",
                        type: "error",
                        html: true
                      });
                    }).done(function (res) {
                      res = JSON.parse(res);
                      if (res.status === 200) {
                        swal("Success!", "Your plan has been updated", "success");
                        _this.set('model.subscription', plan);
                        _this.planEnabled();
                        return _this.sendAction();
                      } else if (res.status === 408) {
                        return swal("Oops!", "You don't seem to have an active credit card, please add or activate a card and try again.", "error");
                      } else if (res.status === 407) {
                        return swal("Oops!", "Your credit card seems to have expired, please use a different card and try again.", "error");
                      } else {
                        return swal("Oops!", "We could not process your upgrade, please <a href='mailto:support@dispel.io'>contact us</a> to resolve the issue", "error");
                      }
                    });
                  } else if (parseInt(plan.id) === parseInt(user.get('downgrade_requested'))) {
                    _this.set('model.user.downgrade_requested', 0);
                  } else {
                    _this.set('model.user.downgrade_requested', plan.id);
                    receiptData = {
                      amount: _price,
                      old_subscription: subscription.get('id'),
                      new_subscription: plan.get('id'),
                      payment_received: false,
                      receipt_type_id: _this.get('model.receiptTypes').findBy('type', 'Downgrade').get('id'),
                      pending: true
                    };
                    _this.generateReceipt(receiptData);
                    _this.sendAction();
                  }
                  if (user.get('isDirty')) {
                    user.save().then(function () {
                      swal("Success!", "Your plan has been updated", "success");
                      _this.notifyPropertyChange('planSuspensionRequested');
                      return _this.planEnabled();
                    });
                  }
                } else {
                  if (subscription.get('monthly_price') === 0) {
                    _title = 'Sign up cancelled';
                    _message = "Okay. We didn't sign you up for a plan.";
                  } else {
                    _title = 'Switch cancelled';
                    _message = "Okay. No changes will be made to your plan.";
                  }
                  swal({
                    title: _title,
                    text: _message,
                    type: 'error',
                    timer: 3000
                  });
                }
              };
            })(this));
          }
        }
      },
      toggleCancelSubscription: function toggleCancelSubscription(user) {
        var _downgradeStatus;
        _downgradeStatus = this.get('model.user.downgrade_requested');
        if (_downgradeStatus === parseInt(this.get('model.plans').findBy('monthly_price', 0).get('id'))) {
          this.set('model.user.downgrade_requested', 0);
        } else {
          swal({
            title: "Are you sure you want to suspend your plan?",
            text: "<p>This will take effect at the end of this billing cycle.</p><p>You will not be able to use Dispel's services if you suspend your plan.</p>",
            type: 'warning',
            html: true,
            showCancelButton: true,
            confirmButtonColor: '#EE3123',
            confirmButtonText: 'Yes, suspend my plan',
            cancelButtonText: 'Nevermind',
            closeOnConfirm: false,
            closeOnCancel: true,
            showLoaderOnConfirm: true,
            allowOutsideClick: true
          }, (function (_this) {
            return function (isConfirm) {
              var _message, _noPlanId, _title, receiptData;
              if (isConfirm) {
                _noPlanId = _this.get('model.plans').findBy('monthly_price', 0).get('id');
                _this.set('model.user.downgrade_requested', _noPlanId);
                receiptData = {
                  amount: 0.00,
                  new_subscription: _noPlanId,
                  old_subscription: _this.get('model.subscription.id'),
                  payment_received: false,
                  receipt_type_id: _this.get('model.receiptTypes').findBy('type', 'Cancel Subscription').get('id'),
                  pending: true
                };
                _this.generateReceipt(receiptData);
                if (user.get('isDirty')) {
                  user.save().then(function () {
                    swal({
                      title: "Plan suspension scheduled",
                      text: "<p>We'll miss you! You can always come back anytime.</p><p>Your plan will be suspended starting next billing cycle.</p>",
                      type: "success",
                      html: true
                    });
                    return _this.notifyPropertyChange('planSuspensionRequested');
                  });
                }
              } else {
                _title = 'Suspension cancelled';
                _message = "Okay. Your plan will continue without interrruption.";
                swal({
                  title: _title,
                  text: _message,
                  type: 'error',
                  timer: 3000
                });
              }
            };
          })(this));
        }
        if (user.get('isDirty')) {
          return user.save().then((function (_this) {
            return function () {
              _this.notifyPropertyChange('planSuspensionRequested');
              swal("Plan restored", "Your plan will continue without interruption.", "success");
              _this.deletePendingReceipts();
              $('.tooltipped').tooltip('remove');
              return setTimeout(function () {
                $('.tooltipped').tooltip();
              }, 200);
            };
          })(this));
        }
      },
      toggleCreditCharge: function toggleCreditCharge(user) {
        var _bool;
        _bool = user.get('overage_autocharge_permission');
        _bool = !_bool;
        this.set('model.user.overage_autocharge_permission', _bool);
        if (user.get('isDirty')) {
          return user.save().then((function (_this) {
            return function () {
              if (_this.get('model.user.overage_autocharge_permission')) {
                return Materialize.toast("<i class='material-icons tiny'>check_circle</i> We'll keep your service running if you go over.", 4000, 'light-blue');
              } else {
                return Materialize.toast("<i class='material-icons tiny'>warning</i> If you go over your plan's limits,<br>you will lose service until next billing cycle.", 4000, 'amber black-text');
              }
            };
          })(this))["catch"]((function (_this) {
            return function () {
              return Materialize.toast("Our servers failed to register this change.<br>With deep self-loathing, we apologize and ask forgiveness.<br>Please  try again later, and let us know.", 4000, 'red');
            };
          })(this));
        }
      },
      cancelDowngrade: function cancelDowngrade(user) {
        var _downgradeStatus;
        _downgradeStatus = this.get('model.user.downgrade_requested');
        if (_downgradeStatus > 0 && _downgradeStatus !== parseInt(this.get('model.plans').findBy('monthly_price', 0).get('id'))) {
          this.set('model.user.downgrade_requested', 0);
          if (user.get('isDirty')) {
            return user.save().then((function (_this) {
              return function () {
                _this.planEnabled();
                swal("Downgrade cancelled", "Your current plan will continue without interrruption.", "success");
                return _this.deletePendingReceipts();
              };
            })(this));
          }
        }
      },
      billingPlansHelp: function billingPlansHelp() {
        return swal({
          title: 'Billing Plans',
          text: "<p>You may upgrade, downgrade, or cancel your subscription at anytime via our “Plans” tab. We believe in honest pricing, and that is why we’ve designed our billing system to only charge you a pro-rated price if you choose to upgrade. Sometimes an upgrade will only cost you <strong> $1 </strong>! If you do decide to downgrade, we will still give you the rest of the billing cycle on the higher tier and modify your plan before the next monthly charge. If you change your mind, that’s no problem at all, you can always undo a planned downgrade or cancelation. If you need further assistance, open the chat window at the bottom-right corner of the page and send us a quick message.</p>",
          imageUrl: "/assets/images/bulb.png",
          html: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
          closeOnConfirm: true,
          showConfirmButton: true
        });
      }
    }
  });

  exports['default'] = SelectSubscriptionComponent;
});