define('dispel-client/features/components/service-connection/component', ['exports', 'ember'], function (exports, _ember) {
  var ServiceConnectionComponent;

  ServiceConnectionComponent = _ember['default'].Component.extend({
    userOperatingSystem: 'unknown',
    osx_version: void 0,
    win_version: void 0,
    android_version: void 0,
    linux_version: void 0,
    mac_user: false,
    win_user: false,
    android_user: false,
    linux_user: false,
    name: (function () {
      var _friendlyName, _service;
      _service = this.get('service');
      _friendlyName = _service.get('friendly_name');
      return _friendlyName;
    }).property('service'),
    imageName: (function () {
      var _imageName, _service, _shortName;
      _service = this.get('service');
      _shortName = _service.get('short_name');
      _imageName = _shortName.toLowerCase().replace(' ', '-');
      return _imageName;
    }).property('service'),
    watchStatus: (function () {
      return setTimeout((function (_this) {
        return function () {
          $('.tooltipped').tooltip();
        };
      })(this), 200);
    }).observes('service.available'),
    didInsertElement: function didInsertElement() {
      var _$box, _element;
      _element = this.get('element');
      _$box = $(_element).find('.service--service-manager--card');
      return setTimeout((function (_this) {
        return function () {
          _$box.addClass('service--service-manager--card--slide-up');
        };
      })(this), 200);
    },
    onInit: (function () {
      return this.checkUserOperatingSystem();
    }).on('init'),
    latestVersion: (function () {
      _ember['default'].$.getJSON(this.config.Variables.API_URL + '/versions/osx/latest').then((function (_this) {
        return function (data) {
          var _latestOSXVersion;
          _latestOSXVersion = data;
          return _this.set('osx_version', _latestOSXVersion.results.version_id);
        };
      })(this));
      _ember['default'].$.getJSON(this.config.Variables.API_URL + '/versions/win/latest').then((function (_this) {
        return function (data) {
          var _latestWINVersion;
          _latestWINVersion = data;
          return _this.set('win_version', _latestWINVersion.results.version_id);
        };
      })(this));
      return _ember['default'].$.getJSON(this.config.Variables.API_URL + '/versions/linux/latest').then((function (_this) {
        return function (data) {
          var _latestLINUXVersion;
          _latestLINUXVersion = data;
          return _this.set('linux_version', _latestLINUXVersion.results.version_id);
        };
      })(this));
    }).on('didInsertElement'),
    userOperatingSystem: 'unknown',
    checkUserOperatingSystem: function checkUserOperatingSystem() {
      var clientStrings, cs, id, nAgt, nVer, os, osVersion;
      nAgt = navigator.userAgent;
      nVer = navigator.appVersion;
      clientStrings = [{
        s: 'Windows 3.11',
        r: /Win16/
      }, {
        s: 'Windows 95',
        r: /(Windows 95|Win95|Windows_95)/
      }, {
        s: 'Windows ME',
        r: /(Win 9x 4.90|Windows ME)/
      }, {
        s: 'Windows 98',
        r: /(Windows 98|Win98)/
      }, {
        s: 'Windows CE',
        r: /Windows CE/
      }, {
        s: 'Windows 2000',
        r: /(Windows NT 5.0|Windows 2000)/
      }, {
        s: 'Windows XP',
        r: /(Windows NT 5.1|Windows XP)/
      }, {
        s: 'Windows Server 2003',
        r: /Windows NT 5.2/
      }, {
        s: 'Windows Vista',
        r: /Windows NT 6.0/
      }, {
        s: 'Windows 7',
        r: /(Windows 7|Windows NT 6.1)/
      }, {
        s: 'Windows 8.1',
        r: /(Windows 8.1|Windows NT 6.3)/
      }, {
        s: 'Windows 8',
        r: /(Windows 8|Windows NT 6.2)/
      }, {
        s: 'Windows NT 4.0',
        r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/
      }, {
        s: 'Windows ME',
        r: /Windows ME/
      }, {
        s: 'Android',
        r: /Android/
      }, {
        s: 'Open BSD',
        r: /OpenBSD/
      }, {
        s: 'Sun OS',
        r: /SunOS/
      }, {
        s: 'Linux',
        r: /(Linux|X11)/
      }, {
        s: 'iOS',
        r: /(iPhone|iPad|iPod)/
      }, {
        s: 'Mac OS X',
        r: /Mac OS X/
      }, {
        s: 'Mac OS',
        r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/
      }, {
        s: 'QNX',
        r: /QNX/
      }, {
        s: 'UNIX',
        r: /UNIX/
      }, {
        s: 'BeOS',
        r: /BeOS/
      }, {
        s: 'OS/2',
        r: /OS\/2/
      }, {
        s: 'Search Bot',
        r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
      }];
      for (id in clientStrings) {
        cs = clientStrings[id];
        if (cs.r.test(nAgt)) {
          this.set('userOperatingSystem', cs.s);
          os = this.get('userOperatingSystem');
          break;
        }
      }
      if (/Windows/.test(os)) {
        osVersion = /Windows (.*)/.exec(os)[1];
        this.set('userOperatingSystem', 'Windows');
        this.set('win_user', true);
        this.set('mac_user', false);
        this.set('linux_user', false);
        this.set('android_user', false);
      }
      switch (this.get('userOperatingSystem')) {
        case 'Mac OS X':
          this.set('mac_user', true);
          this.set('win_user', false);
          this.set('linux_user', false);
          this.set('android_user', false);
          osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1];
          break;
        case 'Linux':
          this.set('linux_user', true);
          this.set('win_user', false);
          this.set('mac_user', false);
          this.set('android_user', false);
          osVersion = /Linux/;
          break;
        case 'Android':
          osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
          this.set('mac_user', false);
          this.set('win_user', false);
          this.set('linux_user', false);
          this.set('android_user', true);
          break;
        case 'iOS':
          osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
          osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
      }
    },
    onMac: (function () {
      return this.userOperatingSystem === 'Mac OS X';
    }).property('onMac'),
    onWindows: (function () {
      return this.userOperatingSystem === 'Windows';
    }).property('onWindows'),
    supported: (function () {
      if (this.onMac || this.onWindows) {
        return true;
      } else {
        return false;
      }
    }).observes('onMac', 'onWindows'),
    actions: {
      setOperatingSystem: function setOperatingSystem() {
        this.set('userOperatingSystem', $(event.target).attr('os'));
        this.notifyPropertyChange('onMac');
        this.notifyPropertyChange('onWindows');
      },
      connectionHelp: function connectionHelp() {
        return swal({
          title: 'Invisible Connections',
          text: "<p>Here you may download the latest version of our Invisible Connections, a portal through which you can connect into our encrypted ephemeral networks. Think of it as your own personal invisibility cloak. With Invisible Connections, you’ll be completely in charge of your online identity, and you will choose which parties have access to your location, identity and content. If you sign in to your email account, your email provider will know it is you, but anyone else trying to read those emails, or steal your content and identity will be stopped at the door. Please refer to our documentation for even more information, and if you have any questions, open the chat window in the bottom-right corner of the page and send us a quick message! </p>",
          imageUrl: "/assets/images/bulb.png",
          html: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
          closeOnConfirm: true,
          showConfirmButton: true
        });
      },
      toggleOSX: function toggleOSX() {
        this.set('mac_user', true);
        this.set('win_user', false);
        this.set('linux_user', false);
        return this.set('android_user', false);
      },
      toggleWIN: function toggleWIN() {
        this.set('mac_user', false);
        this.set('win_user', true);
        this.set('linux_user', false);
        return this.set('android_user', false);
      },
      toggleDROID: function toggleDROID() {
        this.set('mac_user', false);
        this.set('win_user', false);
        this.set('linux_user', false);
        return this.set('android_user', true);
      },
      toggleLINUX: function toggleLINUX() {
        this.set('mac_user', false);
        this.set('win_user', false);
        this.set('android_user', false);
        return this.set('linux_user', true);
      }
    }
  });

  exports['default'] = ServiceConnectionComponent;
});