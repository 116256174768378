define('dispel-client/features/billing/cards/route', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin) {
  var BillingCardsRoute;

  BillingCardsRoute = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], {
    titleToken: 'Cards',
    renderTemplate: function renderTemplate() {
      return this.render({
        into: 'billing',
        outlet: 'cards'
      });
    },
    model: function model() {
      return _ember['default'].RSVP.hash({
        cards: this.store.query('credit-card', {
          user_id: this.get('currentUser.id')
        }),
        user: this.store.peekRecord('user', this.get('currentUser.id'))
      });
    },
    actions: {
      resetModel: function resetModel() {
        return this.refresh();
      }
    }
  });

  exports['default'] = BillingCardsRoute;
});