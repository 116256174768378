define("dispel-client/features/components/update-password/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.10",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 63,
            "column": 0
          }
        },
        "moduleName": "dispel-client/features/components/update-password/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("form");
        dom.setAttribute(el1, "class", "col s12 update-password");
        dom.setAttribute(el1, "data-parsley-ui-enabled", "true");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "input-field col s8");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "for", "password");
        var el5 = dom.createTextNode("Your current password");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" .row ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "input-field col s8");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "for", "password");
        var el5 = dom.createTextNode("Your new password");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("small");
        dom.setAttribute(el4, "class", "validation-error");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" .row ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "input-field col s8");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "for", "password");
        var el5 = dom.createTextNode("Confirm new password");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("small");
        dom.setAttribute(el4, "class", "validation-error");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" .row ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("blockquote");
        dom.setAttribute(el3, "class", "col s12 m8");
        var el4 = dom.createTextNode("Passwords must be at least 8 characters, and must contain one number, one uppercase, one lowercase, and one special character.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col s12 m4");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        var el5 = dom.createTextNode("Update password");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" .row ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [5, 1]);
        var element2 = dom.childAt(element0, [9, 1]);
        var element3 = dom.childAt(element2, [3]);
        var element4 = dom.childAt(element0, [13, 3, 1]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1]), 1, 1);
        morphs[1] = dom.createMorphAt(element1, 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [5]), 0, 0);
        morphs[3] = dom.createMorphAt(element2, 1, 1);
        morphs[4] = dom.createAttrMorph(element3, 'class');
        morphs[5] = dom.createMorphAt(dom.childAt(element2, [5]), 0, 0);
        morphs[6] = dom.createAttrMorph(element4, 'class');
        morphs[7] = dom.createElementMorph(element4);
        return morphs;
      },
      statements: [["inline", "input", [], ["type", "password", "required", true, "autocapitalize", "off", "autocorrect", "off", "value", ["subexpr", "@mut", [["get", "currentPassword", ["loc", [null, [11, 14], [11, 29]]]]], [], []], "class", ["subexpr", "concat", ["update-password--current-password", " "], [], []]], ["loc", [null, [5, 6], [12, 8]]]], ["inline", "input", [], ["type", "password", "required", true, "autocapitalize", "off", "autocorrect", "off", "value", ["subexpr", "@mut", [["get", "password", ["loc", [null, [27, 14], [27, 22]]]]], [], []], "data-parsley-pattern", "^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=]).*$", "data-parsley-trigger", "change keydown", "data-parsley-validation-threshold", "3", "class", ["subexpr", "concat", ["update-password--new-password", " "], [], []]], ["loc", [null, [21, 6], [31, 8]]]], ["content", "passwordError", ["loc", [null, [34, 38], [34, 55]]]], ["inline", "input", [], ["type", "password", "value", ["subexpr", "@mut", [["get", "confirmPassword", ["loc", [null, [42, 14], [42, 29]]]]], [], []], "autocapitalize", "off", "autocorrect", "off", "autofill", "off", "class", ["subexpr", "concat", ["signup--confirm-password", " ", "update-password--confirm-password", " "], [], []]], ["loc", [null, [39, 6], [47, 8]]]], ["attribute", "class", ["subexpr", "if", [["get", "model.user.password_confirmation", ["loc", [null, [49, 24], [49, 56]]]], "active"], [], ["loc", [null, [49, 19], [49, 67]]]]], ["content", "confirmPasswordError", ["loc", [null, [50, 38], [50, 62]]]], ["attribute", "class", ["concat", ["waves-effect waves-light ", ["get", "buttonStatus", ["loc", [null, [58, 48], [58, 60]]]]]]], ["element", "action", ["updatePassword"], [], ["loc", [null, [58, 64], [58, 91]]]]],
      locals: [],
      templates: []
    };
  })());
});