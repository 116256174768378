define('dispel-client/features/signup/done/route', ['exports', 'ember', 'simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _ember, _simpleAuthMixinsUnauthenticatedRouteMixin) {
  var SignupDoneRoute;

  SignupDoneRoute = _ember['default'].Route.extend(_simpleAuthMixinsUnauthenticatedRouteMixin['default'], {
    beforeModel: function beforeModel(transition) {
      var credentialsIncomplete, paymentTabIncomplete, planTabIncomplete;
      this._super(transition);
      credentialsIncomplete = !this.controllerFor('signup').get('credentialsTabComplete');
      planTabIncomplete = !this.controllerFor('signup').get('planTabComplete');
      paymentTabIncomplete = !this.controllerFor('signup').get('paymentTabComplete');
      if (credentialsIncomplete || planTabIncomplete || paymentTabIncomplete) {
        return this.transitionTo('signup.credentials');
      }
    }
  });

  exports['default'] = SignupDoneRoute;
});