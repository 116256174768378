define("dispel-client/helpers/time-duration", ["exports", "ember"], function (exports, _ember) {
  var TimeDurationHelper, timeDuration;

  exports.timeDuration = timeDuration = function (starting_datetime, ending_datetime) {
    var duration, ms, s;
    ms = moment(ending_datetime).diff(moment(starting_datetime));
    duration = moment.duration(ms);
    return s = Math.floor(duration.asHours()) + moment.utc(ms).format(":mm");
  };

  TimeDurationHelper = _ember["default"].Helper.helper(timeDuration);

  exports.timeDuration = timeDuration;
  exports["default"] = TimeDurationHelper;
});