define('dispel-client/helpers/credit-bandwidth', ['exports', 'ember'], function (exports, _ember) {
  var CreditBandwidthHelper, creditBandwidth;

  exports.creditBandwidth = creditBandwidth = function (params) {
    var additional_bandwidth, credit_reserve;
    credit_reserve = params[0];
    additional_bandwidth = credit_reserve * 10;
    additional_bandwidth = additional_bandwidth.toString();
    return additional_bandwidth;
  };

  CreditBandwidthHelper = _ember['default'].HTMLBars.makeBoundHelper(creditBandwidth);

  exports.creditBandwidth = creditBandwidth;
  exports['default'] = CreditBandwidthHelper;
});