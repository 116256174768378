define('dispel-client/features/components/billing-receipts/component', ['exports', 'ember'], function (exports, _ember) {
  var BillingReceiptsComponent;

  BillingReceiptsComponent = _ember['default'].Component.extend({
    sortedReceipts: (function () {
      return _ember['default'].ArrayProxy.extend(_ember['default'].SortableMixin).create({
        sortProperties: ['id'],
        sortAscending: false,
        content: this.get('model.receipts')
      });
    }).property('receipts'),
    actions: {
      billingReceiptsHelp: function billingReceiptsHelp() {
        return swal({
          title: 'Receipts',
          text: "<p>Here you can keep track of your monthly charges and other expenditures with Dispel. Each receipt has a unique Invoice Id, which you may use when referencing a specific transaction. If you need further assistance, open the chat window at the bottom-right corner of the page and send us a quick message.</p>",
          imageUrl: "/assets/images/bulb.png",
          html: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
          closeOnConfirm: true,
          showConfirmButton: true
        });
      }
    }
  });

  exports['default'] = BillingReceiptsComponent;
});